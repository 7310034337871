import React from 'react';

function Input(props) {
	return(
		<div className={"col-md-"+props.colSize}>
	        <div className="form-group">
		        <label>{props.label}</label>
		        <input type={props.type} className="form-control" placeholder={props.placeholder} onChange={props.onChange} name={props.name} value={props.value} />
	        </div>
	    </div>
	);
}

export default Input;