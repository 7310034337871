import React, {useState,useEffect} from 'react';
import {getData,updateItemObj,insertData,deleteItem} from '../tools/Helper';
import Botones from './Botones';
import NuevaCategoria from './NuevaCategoria';
import LoadingMsg from '../tools/LoadingMsg';
import StatusMsg from '../tools/StatusMsg';

import { useAuth } from "../../context/auth";

function Catalogos() {

	const { deleteTokens } = useAuth();

	function logOut() {
		deleteTokens();
	}

	let isSubscribed = true;

	const [catalogo, setCatalogo] = useState([]);
	const [id, setId] = useState([]);
	const [agregar, setAgregar] = useState([]);

	const [message, setMessage] = useState("");
	const [status, setStatus] = useState("");
	const [loading, setLoading] = useState("");


	const getTipos = async() =>{
		setLoading(true);
		const data = await getData('catalogos');
		if (isSubscribed) {
			if(data.status==="success"){
				setCatalogo(data);
				console.log(data);
			}
			setLoading(false);
		}
	}

	useEffect(() => { 
		getTipos();
		// eslint-disable-next-line
    	return () => {isSubscribed=false};
  	},[]);

  	const editarLista = async() =>{
  		setMessage("");
		setStatus("ok");
  		setLoading(true);
  		let ruta = "";
  		if(id[2]===1){
			ruta = "tipos-almacen/";
		}else if(id[2]===2){
			ruta = "tipos-documento/";
		}else if(id[2]===3){
			ruta = "tipos-pago/";
		}else if(id[2]===4){
			ruta = "bancos/";
		}
  		const editar = await updateItemObj(ruta + id[0], {descripcion: id[3]});
  		if (isSubscribed) {
  			await getTipos();
  			if(editar.status === "success"){
				setStatus("success");
				setMessage("Se ha editado con exito.");
			}else{
				setStatus("error");
				setMessage("Ha ocurrido un error. Por favor intente de nuevo");
			}
		setLoading(false);
  		}
	}

	const deleteLista = async() =>{
		setMessage("");
		setStatus("ok");
  		setLoading(true);
  		let ruta = "";
  		if(id[2]===1){
			ruta = "tipos-almacen/";
		}else if(id[2]===2){
			ruta = "tipos-documento/";
		}else if(id[2]===3){
			ruta = "tipos-pago/";
		}else if(id[2]===4){
			ruta = "bancos/";
		}
  		const eliminar = await deleteItem(ruta + id[0]);
  		if (isSubscribed) {
  			await getTipos();
  			if(eliminar.status === "success"){
				setStatus("success");
				setMessage("Se ha eliminado con exito.");
			}else{
				setStatus("error");
				setMessage("Ha ocurrido un error. Por favor intente de nuevo");
			}
		setLoading(false);
  		}
	}

	useEffect(() => { 
		if(id[1]===true){
			console.log("do editar ");
			console.log(id[0]);
			console.log(id[2]);
			console.log(id[3]);
			editarLista();
		}else if(id[1]===false){
			console.log("do eliminar")
			console.log(id[0]);
			deleteLista();
		}
  	},[id]);

  	useEffect(() => {
  		if(agregar.length>0){
  			setMessage("");
			setStatus("ok");
  			setLoading(true);
			const agregarTipo = async()=>{
				let ruta = "";
				if(agregar[0]===1){
					ruta = "tipos-almacen";
				}else if(agregar[0]===2){
					ruta = "tipos-documento";
				}else if(agregar[0]===3){
					ruta = "tipos-pago";
				}else if(agregar[0]===4){
					ruta = "bancos";
				}

				const add = await insertData(ruta, {descripcion:agregar[1]});
				if (isSubscribed) {
					await getTipos();
					if(add.status === "success"){
						await setStatus("success");
						await setMessage("Se se ha agregado con exito.");
					}else{
						setStatus("error");
						setMessage("Ha ocurrido un error. Por favor intente de nuevo");
					}
				setLoading(false);
				}
			}
			agregarTipo();
  		}

  	},[agregar]);

  	const tiposDocumento = catalogo.documento ? catalogo.documento.map((tipo) =>
	  <li key={tipo.id_tipo_documento} id={tipo.id_tipo_documento}>
		{tipo.descripcion}
		<Botones 
		  	descripcion={tipo.descripcion}
		  	id={tipo.id_tipo_documento}
		  	setId={setId} 
		  	tipo={2}
		/>
	  </li>)
	 : "Cargando...";

	const tiposAlmacen = catalogo.almacen ? catalogo.almacen.map((tipo) =>
	  <li key={tipo.id_tipo_almacen} id={tipo.id_tipo_almacen}>
	  	{tipo.descripcion}
	  	<Botones 
		  	descripcion={tipo.descripcion}
		  	id={tipo.id_tipo_almacen} 
		  	setId={setId}
		  	tipo={1}
	  	/>
	  </li>)
	 : "Cargando...";

	const tiposPago = catalogo.pago ? catalogo.pago.map((tipo) =>
	  <li key={tipo.id_tipo_pago} id={tipo.id_tipo_pago}>
		{tipo.descripcion}
	  	<Botones 
		  	descripcion={tipo.descripcion}
		  	id={tipo.id_tipo_pago} 
		  	setId={setId}
		  	tipo={3}
		/>
	  </li>)
	 : "Cargando...";

	 const bancos = catalogo.banco ? catalogo.banco.map((tipo) =>
	  <li key={tipo.id_banco} id={tipo.id_banco}>
		{tipo.descripcion}
	  	<Botones 
		  	descripcion={tipo.descripcion}
		  	id={tipo.id_banco} 
		  	setId={setId}
		  	tipo={4}
		/>
	  </li>)
	 : "Cargando...";

	return (
	<div className="content">
		<div className="row">
			<div className="col-md-12 pt-2">
				<div className="card">
					<div className="container-fluid px-4">
						<div className="card-body">
							<LoadingMsg loading={loading} />
							<StatusMsg msg={message} status={status} />
							{loading===false ? <div className="row">
								<div className="col-6 col-xl-4">
									<h3 className="mb-1">Tipos de almacen</h3>
									<NuevaCategoria tipo={1} setAgregar={setAgregar} />
									<ol>{tiposAlmacen}</ol>
								</div>
								<div className="col-6 col-xl-8">
									<div className="row">
										<div className="col-12 col-xl-6">
											<h3 className="mb-1">Tipos documentos</h3>
											<NuevaCategoria tipo={2} setAgregar={setAgregar} />
											<ol>{tiposDocumento}</ol>
										</div>
										<div className="col-12 col-xl-6">
											<h3 className="mb-1">Metodos de pago</h3>
											<NuevaCategoria tipo={3} setAgregar={setAgregar} />
											<ol>{tiposPago}</ol>

											<h3 className="mb-1">Bancos</h3>
											<NuevaCategoria tipo={4} setAgregar={setAgregar} />
											<ol>{bancos}</ol>
										</div>
									</div>
								</div>
							</div> : ""}				
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	);
}

export default Catalogos;