import React, {useState,useEffect} from 'react';
import {getData} from '../../tools/Helper';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Sugerencias=({id})=>{
	
	const[lista, setLista] = useState([]);

	useEffect(()=>{

		const getLista = async(datos) =>{
			setLista([]);

			console.log(id)
			const data = await getData('sugerencias-entrega/'+id);
			/*if(data.status==="error"){
			  logOut();
			}*/
			if(data.status==="success"){
			  setLista(data.datos);
			  console.log(data)
			}else{
			  setLista([]);
			  console.log(data)
			}

		}

		getLista(id);

	},[id]);

	return(
		lista.length>0 ? 
		<React.Fragment>
			<div class="alert alert-info" role="alert">
				<span className="font-weight-bold">Se han encontrado las siguientes sugerencias de facturas en la misma zona:</span>
				<Row className="pt-3 font-weight-bold">
					<Col className="text-center">N° Factura</Col>
					<Col className="text-center">Cliente</Col>
					<Col className="text-center">Consignatario</Col>
				</Row>
				{lista.map((factura) =>
				  	<Row>
				  	<Col className="text-center">{factura.numero_factura}</Col>
				  	<Col className="text-center">{factura.cliente}</Col>
				  	<Col className="text-center">{factura.consignatario}</Col>
				  	</Row>)}
			</div>
		</React.Fragment> : ""
	);

}

export default Sugerencias;