import React, {useState,useEffect} from 'react';
import EntregaCompleta from './EntregaCompleta';
import Pedidos from './Pedidos';
import FacturaPendiente from './FacturaPendiente';
import FacturaPagada from './FacturaPagada';
import FacturaEntregaIncompleta from './FacturaEntregaIncompleta';
import FacturaInformacionIncompleta from './FacturaInformacionIncompleta';
import SeguimientoFacturas from './SeguimientoFacturas';
import FacturasGrafica from './FacturasGrafica';
import DatePicker from './DatePicker';
import LoadingMsg from '../tools/LoadingMsg';

import {insertData} from '../tools/Helper';

function Dashboard() {

  const fecha = new Date();

  const [loading, setLoading] = useState(false);
  const [datos, setDatos] = useState({inicio:fecha, fin:fecha});
  const [lista, setLista] = useState([]);

  const getLista = async(datos) =>{
    setLista([]);
    setLoading(true);
    const data = await insertData('informacion-facturas', datos);
    /*if(data.status==="error"){
      logOut();
    }*/
    if(data.status==="success"){
      setLista(data.datos);
      console.log(data)
    }else{
      setLista([]);
    }
    setLoading(false);
  }

  useEffect(()=>{

    const fecha1 = datos.inicio.getFullYear() + ((datos.inicio.getMonth()+1) < 10 ? '/0' : '/') + 
    (datos.inicio.getMonth()+1) + (datos.inicio.getDate() < 10 ? '/0' : '/') + datos.inicio.getDate() + " 00:01:00";

    const fecha2 = datos.fin.getFullYear() + ((datos.fin.getMonth()+1) < 10 ? '/0' : '/') + 
    (datos.fin.getMonth()+1) + (datos.fin.getDate() < 10 ? '/0' : '/') + datos.fin.getDate() + " 23:59:59";

    getLista({fecha_inicio: fecha1, fecha_fin:fecha2});
  },[datos])

  const setFechaInicio = (data) =>{
    setDatos({
      ...datos,
      'inicio': data,
    })
  }

  const setFechaFin = (data) =>{
    setDatos({
      ...datos,
      'fin': data,
    })
  }

  return (
    <div>
      <DatePicker setFechaInicio={setFechaInicio} setFechaFin={setFechaFin} fechaInicio={datos.inicio} fechaFin={datos.fin} />
      <LoadingMsg loading={loading} />
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <EntregaCompleta numero={lista.entrega_completa}/>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <Pedidos numero={lista.en_proceso} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6">
        <FacturaPendiente numero={lista.pendientes} />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <FacturaPagada numero={lista.pagadas} />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <FacturaEntregaIncompleta numero={lista.entrega_incompleta} />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <FacturaInformacionIncompleta numero={lista.info_incompleta} />
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <SeguimientoFacturas />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <FacturasGrafica />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;