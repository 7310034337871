import React, {useState,useEffect} from 'react';

import {getData,insertData} from '../tools/Helper';

import NuevaFactura from './NuevaFactura';
import ListaFacturas from './ListaFacturas';
import { useAuth } from "../../context/auth";

const Facturacion = () => {

	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("ok");
	const [message, setMessage] = useState("");

	const [tab, setTab] = useState(1);

	const setCurrentTab = async(id) =>{
		if(!loading){
			setLoading(true);
			setMessage("");
			setStatus("ok")
			setTab(id);
			if(id===2 || id===21 || id ===25 || id===29){
				await getLista(id + "," + (id+1) + "," + (id+2) + "," + (id+3));
			}else if(id===18){
				const fecha = new Date();
				setFechaCorte(fecha);
			}else{
				await getLista(id);
			}
			if(isSubscribed){
				setLoading(false);
			}
		}
	}

	const { deleteTokens } = useAuth();

	function logOut() {
		deleteTokens();
	}

	let isSubscribed = true;

	const [lista, setLista] = useState([]);

	const getLista = async(factura) =>{

		setLista([]);
		const data = await getData('facturas/' + factura);
		if (isSubscribed) {
			if(data.status==="auth-error"){
				logOut();
			}
			if(data.status==="success"){
				setLista(data.datos);
			}else{
				setLista([]);
			}
		}
	}

	useEffect(() => { 
		// eslint-disable-next-line
    	return () => {isSubscribed=false};
  	},[]);

	const [fechaCorte, setFechaCorte] = useState(null);

	useEffect(() => { 
		if(fechaCorte !== null){
			const nuevaFecha = fechaCorte.getFullYear() + ((fechaCorte.getMonth()+1) < 10 ? '-0' : '-') + (fechaCorte.getMonth()+1) + 
		   	(fechaCorte.getDate() < 10 ? '-0' : '-') + fechaCorte.getDate();

		   	const getCorte = async() =>{
				setLista([]);
				setLoading(true);
				const data = await insertData('facturas-corte',{fecha:nuevaFecha});
				if (isSubscribed) {
					if(data.status==="auth-error"){
						logOut();
					}
					if(data.status==="success"){
						setLista(data.datos);
						setLoading(false);
					}else{
						setLista([]);
						setLoading(false);
					}
				}
			}
		    getCorte(nuevaFecha);
		    console.log(fechaCorte);
		}
		// eslint-disable-next-line
	}, [fechaCorte]);

	return (
		<React.Fragment>
			<div className="content">
				<div className="row">
					<div className="col-md-12 pt-2">
						<NuevaFactura getLista={setCurrentTab} />
					</div>
				</div>
			</div>
			<div className="content">
				<div className="row">
					<div className="col-md-12">
						<ListaFacturas lista={lista} loading={loading} status={status} message={message} tab={tab}
						setCurrentTab={setCurrentTab} setStatus={setStatus} setLoading={setLoading} setMessage={setMessage} setFechaCorte={setFechaCorte} fechaCorte={fechaCorte} />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Facturacion;