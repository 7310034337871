import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';

import LoadingMsg from '../tools/LoadingMsg';
import {getData} from '../tools/Helper';

import Firmas from './Firmas';
import Botones from './Botones';

const FirmarModal = ({show,handleClose,id}) =>{

	const [firmas, setFirmas] = useState([]);
	const [loading, setLoading] = useState(false);

	const getFirmas = async(factura) =>{
		setLoading(true);
		setFirmas([]);
	    const data = await getData('firmas/' + factura);
	    setFirmas(data.datos);
	    console.log(data)
	    setLoading(false);
	}

	useEffect(() => { 
		if(show===true){
			getFirmas(id);
		}
	}, [show,id])

	return(
	    <Modal show={show} onHide={handleClose}
	    dialogClassName="modal-90w">
	        <Modal.Header closeButton>
	            <Modal.Title>Historial de firmas</Modal.Title>
	        </Modal.Header>
	        <Modal.Body>
	        	<LoadingMsg loading={loading} />
	        	<Firmas loading={loading} firmas={firmas}/>
	        </Modal.Body>
	        <Modal.Footer>
	        	<Botones handleClose={handleClose}/>
	        </Modal.Footer>
	    </Modal>
	);
}

export default FirmarModal;