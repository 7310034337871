import React from 'react';
import Modal from 'react-bootstrap/Modal';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const ComentarioModal = ({show,handleClose,setComentario,comentario,aceptarInfo}) =>{

	const formulario=()=>{
	    return(
	        <Form>
		       	<Form.Group controlId="exampleForm.ControlTextarea1">
				    <Form.Label>Comentario</Form.Label>
				    <Form.Control as="textarea" rows="3" name="comentario" onChange={setComentario} value={comentario} />
				</Form.Group>
	        </Form>
	    );

	}

	return(
	    <Modal show={show} onHide={handleClose}>
	        <Modal.Header closeButton>
	            <Modal.Title>Añadir comentario</Modal.Title>
	        </Modal.Header>
	        <Modal.Body>
	        	{formulario()}
	        </Modal.Body>
	        <Modal.Footer>
	        	<Button variant="secondary" onClick={handleClose}>
		        	Cancelar
		        </Button>
		        <Button variant="primary" onClick={aceptarInfo}>
		        	Firmar
		        </Button>
	        </Modal.Footer>
	    </Modal>
	);
}

export default ComentarioModal;