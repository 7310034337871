import React from 'react';

function FacturasGrafica() {
  return (
    <div className="card card-chart">
      <div className="card-header">
        <h5 className="card-title">Facturas</h5>
        <p className="card-category">Completas/Incompletas</p>
      </div>
      <div className="card-body">
        <canvas id="speedChart" width="400" height="100"></canvas>
      </div>
      <div className="card-footer">
        <div className="chart-legend">
          <i className="fa fa-circle text-info"></i> Completas
          <i className="fa fa-circle text-warning"></i> Incompletas
        </div>
        <hr />
      </div>
    </div>
  );
}

export default FacturasGrafica;