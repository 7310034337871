

const base= 'http://golsbita.golsystems.com/api/';

export async function insertData(path,obj){

	let isSubscribed = true;

	const token = JSON.parse(localStorage.getItem('tokens')) ? JSON.parse(localStorage.getItem('tokens')).access_token : '';
	try{
		const requestOptions = {
			method: 'POST',
			headers: { 
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			},
			body: JSON.stringify(obj)
		};
		console.log("data" + path);
		console.log("obj" + JSON.stringify(obj));
		let response = await fetch(base+path, requestOptions);
		if (isSubscribed) {
		let data = await response.json();
		return data;
		}
	}catch(err){
		return {status:'error', message: err};
	}

	return () => {isSubscribed = false};

}

export async function getData(path){

	const token = JSON.parse(localStorage.getItem('tokens')) ? JSON.parse(localStorage.getItem('tokens')).access_token : '';
	try{
		const requestOptions = {
			method: 'GET',
			headers: {
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			}
		};
		console.log("data" + path);
		let response = await fetch(base+path,requestOptions);
		let data = await response.json();
		return data;
	}catch(err){
		return {status:'error', message: err};
	}
}

export async function getDataPost(path,obj){
	const token = JSON.parse(localStorage.getItem('tokens')) ? JSON.parse(localStorage.getItem('tokens')).access_token : '';
	try{
		const requestOptions = {
			method: 'POST',
			headers: {
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			},
			body: JSON.stringify(obj)
		};
		console.log("data" + path);
		let response = await fetch(base+path,requestOptions);
		let data = await response.json();
		return data;
	}catch(err){
		return {status:'error', message: err};
	}
}

export async function updateItem(ruta){
	const token = JSON.parse(localStorage.getItem('tokens')) ? JSON.parse(localStorage.getItem('tokens')).access_token : '';
	try{
		const requestOptions = {
			method: 'PUT',
			headers: { 
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			},
		};
		console.log("data" + ruta);
		let response = await fetch(base+ruta,requestOptions);
		let data = await response.json();
		return data;

	}catch(err){
		return {status:'danger', message: err};
	}
}

export async function updateItemObj(ruta,obj){
	const token = JSON.parse(localStorage.getItem('tokens')) ? JSON.parse(localStorage.getItem('tokens')).access_token : '';
	try{
		const requestOptions = {
			method: 'PUT',
			headers: { 
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			},
			body: JSON.stringify(obj),
		};
		console.log("data" + ruta);
		console.log("obj" + JSON.stringify(obj));
		let response = await fetch(base+ruta,requestOptions);
		let data = await response.json();
		return data;

	}catch(err){
		return {status:'danger', message: err};
	}
}

export async function deleteItem(ruta){
	const token = JSON.parse(localStorage.getItem('tokens')) ? JSON.parse(localStorage.getItem('tokens')).access_token : '';
	try{
		const requestOptions = {
			method: 'DELETE',
			headers: { 
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			}
		};
		console.log("data" + ruta);
		let response = await fetch(base+ruta,requestOptions);
		let data = await response.json();
		return data;

	}catch(err){
		return {status:'danger', message: err};
	}
}

export async function download(){
	const token = JSON.parse(localStorage.getItem('tokens')) ? JSON.parse(localStorage.getItem('tokens')).access_token : '';
	try{
		const requestOptions = {
			method: 'GET',
			headers: { 
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			},
			responseType: 'blob'
		};
		let response = await fetch(base+'descargar-formato',requestOptions);
		let data = await response;
		return data.blob();
	}catch(err){
		return {status:'danger', message: err};
	}
}
