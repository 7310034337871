import React from 'react';
import Modal from 'react-bootstrap/Modal';

import StatusMsg from '../../tools/StatusMsg';
import LoadingMsg from '../../tools/LoadingMsg';

import Formulario from './Formulario';
import Botones from '../Botones';

const FirmarModal = ({show,handleClose,loading,status,message,fecha,id,handleChange,firmarData,titulo,factura,catalogo,handleChangeTipo}) =>{
	return(
	    <Modal show={show} onHide={handleClose}>
	        <Modal.Header closeButton>
	            <Modal.Title>{titulo}</Modal.Title>
	        </Modal.Header>
	        <Modal.Body>
	        	<LoadingMsg loading={loading}/>
	        	<StatusMsg status={status} msg={message}/>
	        	<Formulario
	        		factura={factura}
	        		fecha={fecha}
	        		id={id}
	        		handleChange={handleChange}
	        		status={status}
	        		loading={loading}
	        		catalogo={catalogo}
	        		handleChangeTipo={handleChangeTipo}
	        	/>
	        </Modal.Body>
	        <Modal.Footer>
	        	<Botones 
	        		handleClose={handleClose}
	        		status={status}
	        		loading={loading}
	        		firmarData={firmarData}
	        	/>
	        </Modal.Footer>
	    </Modal>
	);
}

export default FirmarModal;