import React, {useState,useEffect} from 'react';
import DataTable from 'react-data-table-component';

import {customStyles, paginacionOpciones,Empty, columnasData} from '../../tools/TableStyles';

import BotonComentarios from '../../timeline/BotonComentarios';
import ModalComentarios from '../../timeline/ModalComentarios';

const Capturadas =({data,cambiarStatus,getLista})=>{

	const handleButtonClick = (state) => {
		cambiarStatus(facturasArray, state.target.id,1);
	};

	/* SELECCION DE MULTIPLES FACTURAS */
	const [hideRowStyle, setHideRowStyle] = useState([]);
	const [facturasArray, setFacturasArray] = useState([]);

	const selectedChange = (state) => {
	  setFacturasArray(state.selectedRows);
	};

	useEffect(() => { 
		if(facturasArray.length>0){
			setHideRowStyle([
			  {
			    when: row => row.id_estatus !== facturasArray[0]['id_estatus'],
			    style: {
			    	display:'none',
			    },
			  },
			]);
		}else{
			setHideRowStyle([]);
		}
	}, [facturasArray]);
	/* --- */

	/* MODAL DE COMENTARIO REFACTURACION */
	const [showInfoComentario, setShowInfoComentario] = useState(false);
	const [comentario, setComentario] = useState("No se han introducido comentarios.")

	const showComentario = async (com) =>{
		if(com !== null){
			setComentario(com);
		}
		await setShowInfoComentario(true);
	}

	const closeComentario = (status) => setShowInfoComentario(false);
	/* --- */

	const columnas = [
		{
			name: 'ACCIONES',
			cell: (row) => <button type="submit" className="btn btn-warning btn-round btn-sm" onClick={handleButtonClick} id={row.id_factura}>TRANSFERIR A ALMACEN</button>,
			ignoreRowClick: true,
			grow: "1.5",
		},
		{
			name: '',
			cell: (row) => <div>
			{row.id_tipo_documento === 7 ? 
				<BotonComentarios comentario={row.comentario} setComentario={showComentario} /> : ""} 
			</div>,
			ignoreRowClick: true,
			compact:true,
			width:"1.8rem"
		},
	]

	return(
		<React.Fragment>
		<DataTable
				columns= {[...columnasData, ...columnas]}
				title = "Facturas Capturadas"
				data={data}
				pagination
				paginationComponentOptions = {paginacionOpciones}
				fixedHeader
				customStyles={customStyles}
				noDataComponent=<Empty />
				selectableRows
				selectableRowsNoSelectAll
				onSelectedRowsChange={selectedChange}
				conditionalRowStyles={hideRowStyle}
			/>
			<ModalComentarios  
				show={showInfoComentario}
				handleClose={closeComentario}
				comentario={comentario}
			/>
		</React.Fragment>
	)
}

export default Capturadas;