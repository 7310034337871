import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';

import Formulario from './Formulario';
import Button from 'react-bootstrap/Button';

const ModalRemisiones = ({show,handleClose,setRemisiones}) =>{

	/* SELECCION DE MULTIPLES FACTURAS */
	const [facturasArray, setFacturasArray] = useState([]);

	const selectedChange = (state) => {
	  setFacturasArray(state.selectedRows);
	};
	/* --- */

	return(
	    <Modal show={show} onHide={handleClose} className="lista-facturas">
	        <Modal.Header closeButton>
	            <Modal.Title>Seleccione remisiones a agregar</Modal.Title>
	        </Modal.Header>
	        <Modal.Body>
	        	<Formulario
	        		selectedChange={selectedChange}
	        	/>
	        </Modal.Body>
	        <Modal.Footer>
	        	<Button variant="secondary" onClick={handleClose}>
		        	Cancelar
		        </Button>
		        <Button variant="primary" onClick={()=>setRemisiones(facturasArray)}>
		        	Firmar
		        </Button>
	        </Modal.Footer>
	    </Modal>
	);
}

export default ModalRemisiones;