import React, {useState,useEffect} from 'react';
import {insertData,updateItemObj} from '../../tools/Helper';

import FirmarModal from './FirmarModal';

const Firma = ({getLista,handleClose,show,titulo,reload,setReload,factura}) =>{

  const fecha = new Date();

  const [data, setData] = useState({
	id_factura: factura.id_factura,
	id_departamento: factura.id_departamento,
	id_estatus: factura.id_estatus,
	nombre: '',
	comentario: '',
	firma: '',
	tipo: 0,
  });

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("ok");
  const [message, setMessage] = useState("");

  const [entregaPersonal, setEntregaPersonal] = useState(false);
  
  const handleChange = e =>{
	setData({
	  ...data,
	  [e.target.name]: e.target.value
	})
  }

  const selectLista = e =>{
	if(e.target.options.selectedIndex === 1){
	  setEntregaPersonal(true);
	}else{
	  setEntregaPersonal(false);
	}
	setData({
	  ...data,
	  'tipo': e.target.options.selectedIndex
	})
  }

  const selectTipoPago = e =>{
	setData({
	  ...data,
	  'tipo': e.target.options.selectedIndex + 1
	})
  }

  useEffect(() => { 
	setData({
	  ...data,
		id_factura: factura.id_factura,
		id_departamento: factura.id_departamento,
		id_estatus: factura.id_estatus,
		nombre: '',
		comentario: '',
		firma: '',
		tipo: 0,
	})

	if(reload === true){
	  setStatus("ok");
	  setReload(false);
	}

  }, [factura])

  const firmarData = async () => {
	var idEstatus = 0;

	setLoading(true);
	setMessage("");
	setStatus("ok");

	const firmar = await insertData("firmas",data);
	console.log(firmar);
	if(firmar.status === "error"){
	  setMessage("Faltan campos por rellenar.");
	  setStatus("error");
	}else if(firmar.id_Log_Firma!==""){

		switch(data.tipo){
			case 0:
			  idEstatus = 8;
			  break;
			case 1:
			 idEstatus = 35;
			  break;
			case 2:
			  idEstatus = 37;
			  break;
			case 3:
			  idEstatus = 40;
			  break;
			default:
			 	idEstatus = 8;
			break;
	  }

	  const statusData = {"id_estatus" : idEstatus};

	  const cambiar = await updateItemObj("cambiar-estatus/" + factura.id_factura,statusData);

		if(cambiar.status === "success"){
		  setStatus("success");
		  setMessage("Se ha marcado como entregado el pedido.");
		  setReload(true);
	  }else{
	  setMessage("Ha ocurrido un error, por favor intente otra vez.");
	  setStatus("error");
	  } 
 
	}else{
	  setMessage("Ha ocurrido un error, por favor intente otra vez.");
	  setStatus("error");
	}    
	
	setLoading(false);
  }

  return (
	<div>
	<FirmarModal 
	  handleChange={handleChange} 
	  loading={loading} 
	  status={status} 
	  message={message}
	  show={show}
	  handleClose={handleClose}
	  fecha={fecha}
	  id={factura.id_factura}
	  firmarData={firmarData}
	  titulo={titulo}
	  selectLista={selectLista}
	  entregaPersonal={entregaPersonal}
	  setTipoPago={selectTipoPago}
	  data={data}
	/>
	</div>
  );
}

export default Firma;