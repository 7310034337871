import React, {useState,useEffect} from 'react';
import ListaFacturasContabilidad from './ListaFacturasContabilidad';
import {getData} from '../tools/Helper';

import { useAuth } from "../../context/auth";

function Contabilidad() {

	const { deleteTokens } = useAuth();

	function logOut() {
		deleteTokens();
	}

	const [lista, setLista] = useState([]);

	let isSubscribed = true;

	const getLista = async(factura) =>{

		const data = await getData('facturas/' + factura);
		if (isSubscribed) {
			if(data.status==="success"){
				setLista(data.datos);
			}else{
				setLista([]);
			}
			if(data.status==="auth-error"){
				logOut();
			}
		}
		
	}

	useEffect(() => { 
		// eslint-disable-next-line
    	return () => {isSubscribed=false};
  	},[]);

	return (
		<div className="content">
			<div className="row">
				<div className="col-md-12 pt-2">
					<ListaFacturasContabilidad 
						lista={lista}
						getLista={getLista}
						setLista={setLista}
					/>
				</div>
			</div>
		</div>
	);
}

export default Contabilidad;