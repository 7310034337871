import React, {useState,useEffect} from 'react';
import {insertData,updateItem,updateItemObj} from '../tools/Helper';

import FirmarModal from './FirmarModal';

const Firma = ({getLista,handleClose,show,titulo,reload,setReload,factura}) =>{

	const fecha = new Date();

	const [data, setData] = useState({
		id_factura: factura.id_factura,
		id_departamento: factura.id_departamento,
		id_estatus: factura.id_estatus,
		nombre: '',
		firma: '',
		no_productos: '',
	});

	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("ok");
	const [message, setMessage] = useState("");
	
	const handleChange = e =>{
		setData({
			...data,
			[e.target.name]: e.target.value
		})
	}

	useEffect(() => { 
		setData({
			...data,
			id_factura: factura.id_factura,
			id_estatus: factura.id_estatus,
			id_departamento: factura.id_departamento,
			nombre: '',
			firma: '',
			no_productos: '',
			facturas: factura.facturas,
		})
		
		if(reload === true){
			setStatus("ok");
			setReload(false);
		}
	}, [factura])

	const firmarData = async () => {
		setLoading(true);
		setMessage("");
		setStatus("ok");

		if(data.facturas.length>0){
			const firmar = await insertData("firma-multiple",data);
			console.log(data);

			if(firmar.status === "error"){
				setMessage("Faltan campos por rellenar.");
				setStatus("error");
			}else if(firmar.status==="success"){
				/*if(factura.id_estatus===29){
					const cambiar = await updateItemObj("cambiar-estatus/" + data.id_factura,{id_estatus:29});
					if(cambiar.status === "success"){
						setStatus("success");
						setMessage("Se ha firmado la factura correctamente.");
						setReload(true);
					}
				}else */ if(factura.id_estatus===12){
					const cambiar = await updateItemObj("cambiar-estatus/" + data.id_factura,{id_estatus:21});
					if(cambiar.status === "success"){
						setStatus("success");
						setMessage("Se ha firmado la factura correctamente.");
						setReload(true);
					}
				}else{
					const facturas= {facturas: data.facturas};
					console.log(facturas);
					const cambiar = await insertData("cambiar-estatus-multiple", facturas);
						if(cambiar.status === "success"){
						setStatus("success");
						setMessage("Se ha firmado la factura correctamente.");
						setReload(true);
					}
				}
			}else{
				setMessage("Ha ocurrido un error, por favor intente otra vez.");
				setStatus("error");
			}
		}else{
			const firmar = await insertData("firmas",data);
			console.log(data);

			if(firmar.status === "error"){
				setMessage("Faltan campos por rellenar.");
				setStatus("error");
			}else if(firmar.status==="success"){
				/*if(factura.id_estatus===29){
					const cambiar = await updateItemObj("cambiar-estatus/" + data.id_factura,{id_estatus:29});
					if(cambiar.status === "success"){
						setStatus("success");
						setMessage("Se ha firmado la factura correctamente.");
						setReload(true);
					}
				}else*/ if(factura.id_estatus===12){
					const cambiar = await updateItemObj("cambiar-estatus/" + data.id_factura,{id_estatus:21});
					if(cambiar.status === "success"){
						setStatus("success");
						setMessage("Se ha firmado la factura correctamente.");
						setReload(true);
					}
				}else{
					const cambiar = await updateItem("cambiar-estatus/" + data.id_factura);
						if(cambiar.status === "success"){
						setStatus("success");
						setMessage("Se ha firmado la factura correctamente.");
						setReload(true);
					}
				}
			}else{
				setMessage("Ha ocurrido un error, por favor intente otra vez.");
				setStatus("error");
			}
		}

		setLoading(false);
	};

	return (
		<React.Fragment>
			<FirmarModal 
				handleChange={handleChange} 
				loading={loading} 
				status={status} 
				message={message}
				show={show}
				handleClose={handleClose}
				fecha={fecha}
				id={factura.id_factura}
				firmarData={firmarData}
				titulo={titulo}
			/>
		</React.Fragment>
	);
}

export default Firma;