import React, {useState,useEffect} from 'react';
import { CSVLink } from "react-csv";
import {Col, Row, Button} from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import InputGroupTipoDocumento from '../formComponents/InputGroupTipoDocumento'; 
import InputGroupEstatus from '../formComponents/InputGroupEstatus';
import InputGroupReporte from '../formComponents/InputGroupReporte';
import moment from 'moment';
import {insertData,getData} from '../tools/Helper';

import StatusMsg from '../tools/StatusMsg';
import LoadingMsg from '../tools/LoadingMsg';

const ConsultaExcel = () => {

	let isSubscribed = true;

	const [status, setStatus] = useState("ok");
	const [statusMsg, setStatusMsg] = useState("");
	const [listaFacturas, setListaFacturas] = useState([]);
	const [catalogoLoaded, setCatalogoLoaded] = useState(false);

	const [listaTipos, setListaTipos] = useState([""]);
	const [listaClientes, setListaClientes] = useState([""]);
	const [listaEstatus, setListaEstatus] = useState([{descripcion:"Todos los estatus", id_estatus: 1}]);

	const headers = [
	  { label: "ID Factura", key: "id_factura" },
	  { label: "N° Factura", key: "numero_factura" },
	  { label: "Fecha Factura", key: "fecha_factura" },
	  { label: "Folio SAT", key: "folio_sat" },
	  { label: "N° Remision", key: "no_remision" },
	  { label: "Fecha Remision", key: "fecha_remision" },
	  { label: "Cliente", key: "cliente" },
	  { label: "Consignatario", key: "consignatario" },
	  { label: "Area Fact.", key: "area_facturacion" },
	  { label: "Vendedor", key: "vendedor" },
	  { label: "Zona", key: "zona" },
	  { label: "Ruta", key: "ruta" },
	  { label: "Tipo de Pago", key: "tipo_pago" },
	  { label: "Subtotal", key: "subtotal" },
	  { label: "IVA", key: "iva" },
	  { label: "Total", key: "total" },
	  { label: "Tipo", key: "tipo_almacen" },
	  { label: "Empresa", key: "empresa" },
	  { label: "N° Pedido", key: "numero_pedido" },
	  { label: "Tipo Documento", key: "tipo_documento" },
	  { label: "Estatus", key: "estatus" },
	  { label: "Fecha de pago", key: "fecha_pago" },
	  { label: "Vencimiento", key: "vencimiento" },
	  { label: "Metodo de pago", key: "metodo_pago" },
	  { label: "Banco", key: "banco" },
	  { label: "Folio Microsip", key: "folio_microsip" },
	  { label: "Fecha de entrega", key: "fecha_entrega" },
	];

	/* SELECCION DE RANGO DE FECHA */
	const [datos, setDatos] = useState({
		fecha_inicio: '',
		fecha_fin: '',
		cliente: '',
		id_tipo_documento: '',
		id_estatus: '',
		inicio: '',
		fin: '',
	});

	const setFechaInicio = (data) =>{
		setDatos({
			...datos,
			'inicio': data,
		})
	}

	const setFechaFin = (data) =>{
		setDatos({
			...datos,
			'fin': data,
		})
	}
	/* --- */

	const botones = ()=>{
		return(
			<React.Fragment>

				<CSVLink data={listaFacturas} headers={headers} 
				  className="btn btn-success btn-round"
				  filename={"Reporte.csv"}
				  >
				  Descargar Excel
				</CSVLink>
			</React.Fragment>
			);
	}

	const [loading, setLoading] = useState(false);

	const handleChangeTipo = e =>{
		const selectedIndex = e.target.options.selectedIndex;
		const id = parseInt(e.target.options[selectedIndex].getAttribute('id'));
		setDatos({
			...datos,
			[e.target.name]: id
		})
	}

	const handleChangeCliente = e =>{
		const selectedIndex = e.target.options.selectedIndex;
		const id = e.target.options[selectedIndex].getAttribute('id');
		setDatos({
			...datos,
			[e.target.name]: id
		})
	}

	const getReporte = async() =>{
		setStatus("ok");
		setStatusMsg("");
		if(datos.inicio === '' || datos.fin === ''){
			setStatusMsg("Por favor introduzca una fecha");
			setStatus("error");
		}else{
			setLoading(true);
			let datosFactura = datos;
			datosFactura['fecha_inicio'] = moment(datosFactura['inicio']).format('YYYY/MM/DD');
			datosFactura['fecha_fin'] = moment(datosFactura['fin']).format('YYYY/MM/DD');
			const reporte = await insertData("reportes", datosFactura);
			console.log(datosFactura);
			if(isSubscribed===true){
				setStatus(reporte.status);
				if(reporte.status === "success"){
					setListaFacturas(reporte.datos);
					console.log(reporte.datos);
				}
				setStatusMsg(reporte.message);
				console.log(reporte);
				setLoading(false);
			}
		}
	}

	const getTipos = async() =>{
		const data = await getData('catalogos');
		if (isSubscribed) {
			if(data.status==="success"){
				console.log(data);

				setDatos({
					fecha_inicio: '',
					fecha_fin: '',
					cliente: '',
					id_tipo_documento: data.documento[0].id_tipo_documento,
					id_estatus: '',
					inicio: '',
					fin: '',
				});
				setListaTipos(data.documento);
				setListaClientes(data.cliente);
				setCatalogoLoaded(true);
			}
		}
	}

	useEffect(() => { 
		getTipos();
		// eslint-disable-next-line
    	return () => {isSubscribed=false};
  	},[]);

  	useEffect(() => { 
			if(datos.id_tipo_documento === 2){
				setListaEstatus([{descripcion:"Pendientes de facturar", id_estatus: 1},
					{descripcion:"Facturadas", id_estatus: 2},
					{descripcion:"Canceladas", id_estatus: 3},
					{descripcion:"Todos los estatus", id_estatus: 4}]);
			}else if(datos.id_tipo_documento === 3){
				setListaEstatus([{descripcion:"Pendientes de pagar", id_estatus: 1},
					{descripcion:"Pagadas", id_estatus: 2},
					{descripcion:"Todos los estatus", id_estatus: 3}]);
			}else{
				setListaEstatus([{descripcion:"Todos los estatus", id_estatus: 1}]);
			}
	}, [datos.id_tipo_documento]);

	useEffect(() => { 
		setDatos({
				...datos,
				'id_estatus': listaEstatus[0].id_estatus,
			})
	}, [listaEstatus]);

	return(
		<div className="card">
			<div className="container-fluid px-4">
				<p className="mt-3">Introduzca un rango de fechas</p>
				<Row>
					<Col>
						<div className="form-group">
							<label>Fecha</label>
							<DateTimePicker
								onChange={setFechaInicio}
								value={datos.inicio}
								className="form-control c-padding"
								disableClock={true}
								name="fecha_inicio"
							/>
						</div>
					</Col>
					<Col>
						<div className="form-group">
							<label>Fecha</label>
							<DateTimePicker
								onChange={setFechaFin}
								value={datos.fin}
								className="form-control c-padding"
								disableClock={true}
								name="fecha_fin"
							/>
						</div>
					</Col>
					<Col>
						<InputGroupReporte catalogoLoaded={catalogoLoaded} type={1} label="Cliente" options={listaClientes} onChange={handleChangeCliente} name="cliente" value={datos["cliente"]} />
					</Col> 
				</Row>
				<Row>
					<Col>
						<InputGroupTipoDocumento catalogoLoaded={catalogoLoaded} label="Tipo Documento" options={listaTipos} onChange={handleChangeTipo} name="id_tipo_documento" value={datos["id_tipo_documento"]} />
					</Col>
					<Col>
						<InputGroupEstatus catalogoLoaded={catalogoLoaded} label="Estatus" options={listaEstatus} onChange={handleChangeTipo} name="id_estatus" value={datos["id_estatus"]} />
					</Col>
					<Col>
						<Button className="mt-4" variant="primary" onClick={getReporte}>
							Aceptar
						</Button>
					</Col>
				</Row>
				<hr className="mb-1"/>
				<div className="tab-content">
					<div id="surtir" className="tab-pane fade in active show">
						<div className="card-body">

							<LoadingMsg loading={loading}/>
							<StatusMsg status={status} msg={statusMsg}/>

							{status === "success" ? botones() : ""}

						</div>
					</div>
				</div>
			</div>
		</div>
	);

}

export default ConsultaExcel;