import React, {useState,useEffect} from 'react';
import {updateItemObj} from '../../tools/Helper';

import FirmarModal from './FirmarModal';

const Firma = ({getLista,handleClose,show,titulo,reload,setReload,factura}) =>{

  const fecha = new Date();

  const lista=["Pedido Completado", "Entrega Incompleta", "Informacion Incompleta", "Devolucion", "Cancelacion"];

  const [data, setData] = useState({
    tipo: 0,
  });

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("ok");
  const [message, setMessage] = useState("");

  const selectLista = e =>{
    setData({
      ...data,
      'tipo': e.target.options.selectedIndex
    })
  }

  useEffect(() => { 
    setData({
      ...data,
      'id_estatus': factura.id_estatus
    })

    if(reload === true){
      setStatus("ok");
      setReload(false);
    }
  }, [factura])

  const firmarData = async () => {
    var idEstatus = 0;

    setLoading(true);
    setMessage("");
    setStatus("ok");

    switch(data.tipo){
      case 0:
        idEstatus = 10;
        break;
      case 1:
       idEstatus = 21;
        break;
      case 2:
        idEstatus = 23;
        break;
      case 3:
        idEstatus = 25;
        break;
      case 4:
        idEstatus = 29;
        break;
      default:
        idEstatus = 10;
        break;
    }

    const statusData = {"id_estatus" : idEstatus};
      const cambiar = await updateItemObj("cambiar-estatus/" + factura.id_factura,statusData);
      console.log(statusData);
      if(cambiar.status === "success"){
        setStatus("success");
        setMessage("Se ha marcado como entregado el pedido.");
        setReload(true);
    }else{
        setStatus("error");
        setMessage("Ha ocurrido un error.");
        setReload(true);
    }
    
    setLoading(false);
  };

  return (
    <div>
    <FirmarModal 
      loading={loading} 
      status={status} 
      message={message}
      show={show}
      handleClose={handleClose}
      fecha={fecha}
      id={factura.id_factura}
      firmarData={firmarData}
      titulo={titulo}
      lista={lista}
      selectLista={selectLista}
    />
    </div>
  );
}

export default Firma;