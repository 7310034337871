import React from 'react';

function SeguimientoFacturas() {
  return (
    <div className="card">
        <div className="card-header">
          <h4 className="card-title"> Seguimiento Facturas</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table">
              <thead className=" text-title">
              <tr>
                <th className="text-center">
                  ID
                </th>
                <th className="text-center">
                  ALMACEN
                </th>
                <th className="text-center">
                  CONFIRMADO
                </th>
                <th className="text-center">
                  SURTIDO
                </th>
                <th className="text-center">
                  COMPLETADO
                </th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td>123456</td>
                  <td className="text-center ">
                    <i className="fa fa-cube text-primary icon-lg"></i>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>234324</td>
                  <td></td>
                  <td className="text-center ">
                    <i className="fa fa-cube text-primary icon-lg"></i>
                    <i className="nc-icon nc-alert-circle-i text-warning "></i>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>242400</td>
                  <td></td>
                  <td></td>
                  <td className="text-center ">
                    <i className="fa fa-cube text-success icon-lg"></i>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>242400</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-center ">
                    <i className="fa fa-cube text-success icon-lg"></i>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div>
              <i className="fa fa-cube text-success"></i>
              Firmado
            </div>
            <div>
              <i className="fa fa-cube text-primary"></i>
              No Firmado
            </div>
          </div>
        </div>
      </div>
  );
}

export default SeguimientoFacturas;