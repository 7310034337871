import React, {useState, useEffect} from 'react';
import Pendientes from './listas/Pendientes';
import Surtidas from './listas/Surtidas';
import Canceladas from './listas/Canceladas';
import Devoluciones from './listas/Devoluciones';

import StatusMsg from '../tools/StatusMsg';
import LoadingMsg from '../tools/LoadingMsg';

const ListaFacturasAlmacen = ({lista,getLista,addLista,setLista}) => {

	const [loading, setLoading] = useState(false);
	const [tab, setTab] = useState(2);

	let isSubscribed = true;

	const setCurrentTab = async(id) =>{
		if(!loading){
			setLoading(true);
			setTab(id);
			if(id===2){
				setLista([]);
				await getLista(id + "," + (id+1) + "," + (id+2) + "," + (id+3));
			}else if(id === 6){
				setLista([]);
				await getLista(id + "," + (id+1) + "," + (id+2));
			}
			else{
				setLista([]);
				await getLista(id + "," + (id+1));
			}
			if(isSubscribed){
				setLoading(false);
			}
		}
	}

	useEffect(() => { 
		// eslint-disable-next-line
		setCurrentTab(2);
		// eslint-disable-next-line
		return () => {isSubscribed=false};
	}, [])

	return (
		<div className="card">
				<div className="container-fluid px-4">
					<ul className="nav nav-tabs tabs-navigate">
						<li>
							<a className={ tab===2 ? "active" : ""} onClick={ () =>{setCurrentTab(2)}} href="#tabs">Pendientes</a>
						</li>
						<li>
							<a className={ tab===6 ? "active" : ""} onClick={ () =>{setCurrentTab(6)}} href="#tabs">Surtidas</a>
						</li>
						<li>
							<a className={ tab===29 ? "active" : ""} onClick={ () =>{setCurrentTab(29)}} href="#tabs">Canceladas</a>
						</li>
						<li>
							<a className={ tab===25 ? "active" : ""} onClick={ () =>{setCurrentTab(25)}} href="#tabs">Devoluciones</a>
						</li>
					</ul>

					<div className="tab-content">
						<div id="surtir" className="tab-pane fade in active show">
							<div className="card-body">

								<LoadingMsg loading={loading}/>
								<StatusMsg/>

								{ tab===2 && loading===false ? <Pendientes data={lista} getLista={setCurrentTab} /> : null }
								{ tab===6 && loading===false ? <Surtidas data={lista} getLista={setCurrentTab} /> : null }
								{ tab===29 && loading===false ? <Canceladas data={lista} getLista={setCurrentTab} /> : null }
								{ tab===25 && loading===false ? <Devoluciones data={lista} getLista={setCurrentTab} /> : null }

							</div>
						</div>
					</div>
				</div>
		</div>
	);
}

export default ListaFacturasAlmacen;