import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import { useAuth } from "../../context/auth";
import { Link } from 'react-router-dom';

function Navbar(props) {

  const username = JSON.parse(localStorage.getItem('tokens')) ? JSON.parse(localStorage.getItem('tokens')).user.nombre_completo : 'no';

  //

  const { deleteTokens } = useAuth();

  var titulo = "";

  if(window.location.pathname==="/"){
    titulo = "Dashboard"
  }else if(props.item===0){
    titulo = "Dashboard"
  }else if(props.item===1){
    titulo = "Facturacion"
  }else if(props.item===2){
    titulo = "Credito y Cobranza"
  }else if(props.item===3){
    titulo = "Contabilidad"
  }else if(props.item===4){
    titulo = "Almacen"
  }else if(props.item===5){
    titulo = "Consultas"
  }else if(props.item===6){
    titulo = "Catalogos"
  }else if(props.item===7){
    titulo = "Iniciar Sesion"
  }else if(props.item===8){
    titulo = "Usuarios"
  }

  function logOut() {
    deleteTokens();
  }

  const panelMenu = (props) =>{
    if(username==='no'){
      return(
          <Dropdown>
            <Dropdown.Toggle className="minibutton" variant="none" id="usuario">
              <i className="nc-icon nc-single-02"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Header><span className="font-weight-bold">No has iniciado sesion</span></Dropdown.Header>
              <Dropdown.Item><Link to="/dashboard" className="link">Iniciar Sesion</Link></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
    }else{
      return(
        <React.Fragment>
          <Dropdown>
            <Dropdown.Toggle className="minibutton" variant="none" id="notificaciones">
              <i className="nc-icon nc-bell-55"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Header>Notificaciones</Dropdown.Header>
              <Dropdown.Item eventKey="2">No hay notificaciones disponibles</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle className="minibutton" variant="none" id="usuario">
              <i className="nc-icon nc-single-02"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Header><span className="font-weight-bold">{username}</span></Dropdown.Header>
              <Dropdown.Item onClick={logOut}>Cerrar Sesion</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </React.Fragment>
      );
    }
  }

  return (
  	<nav className="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
        <div className="container-fluid">
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button type="button" className="navbar-toggler">
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </button>
            </div>
            <a className="navbar-brand" href="/">{titulo}</a>
          </div>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navigation">
            <form className="px-3">
              <div className="input-group no-border">
                <input type="text" defaultValue="Buscar..." className="form-control" placeholder="Buscar..."/>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <i className="nc-icon nc-zoom-split"></i>
                  </div>
                </div>
              </div>
            </form>
            {panelMenu()}
          </div>
        </div>
      </nav>

  );
}

export default Navbar;