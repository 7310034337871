import React, {useState,useEffect} from 'react';
import Completas from './listas/Completas';
import RemisionesCompletas from './listas/RemisionesCompletas';
import RemisionesFacturadas from './listas/RemisionesFacturadas';
import Pagadas from './listas/Pagadas';
import Pendientes from './listas/Pendientes';

import StatusMsg from '../tools/StatusMsg';
import LoadingMsg from '../tools/LoadingMsg';
import {updateItem, insertData} from '../tools/Helper';

import {getData} from '../tools/Helper';

import { CSVLink } from "react-csv";

const ListaFacturasCredito = ({lista,getLista,addLista,setLista,addListaCompletados,getListaCompletados}) => {

	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("ok");
	const [message, setMessage] = useState("");

	const [data, setData] = useState([]);
	const [empty, setEmpty] = useState(false);

	const getDatosExcel = async() =>{
		const datos = await getData('facturas/11,12,13,15,37,38,39,40,41,42');
		if (isSubscribed) {
			console.log(datos);
			if(datos.status==="success"){
				setData(datos.datos);
				console.log(datos.datos);
			}else if(datos.status==="not_found"){
				setEmpty(true);
			}else{
				setLista([]);
			}
		}
	}

	let isSubscribed = true;

	const cambiarStatus = async(listaFacturas, id, idLista) =>{
		setLoading(true);
		console.log(listaFacturas);
		if(listaFacturas.length<1){
			const ruta = "cambiar-estatus/"+id;

			const cambiar = await updateItem(ruta);
			if (isSubscribed) {
				if(cambiar.status === "success"){
					await setCurrentTab(idLista);
					setStatus("success");
					setMessage("Se ha transferido con exito.");
				}else{
					setStatus("error");
					setMessage("Ha ocurrido un error. Por favor intente de nuevo");
					setLoading(false);
				}
			}
		}else{
			const ruta = "cambiar-estatus-multiple";

			const cambiar = await insertData(ruta, {facturas: listaFacturas});
			if (isSubscribed) {
				if(cambiar.status === "success"){
					setCurrentTab(idLista);
					setStatus("success");
					setMessage("Se ha transferido con exito.");
				}else{
					setStatus("error");
					setMessage("Ha ocurrido un error. Por favor intente de nuevo");
					setLoading(false);
				}
			}
		}
	}

	const mandarRemisiones = async(lista) =>{
		if(lista['facturas'][0]["id_estatus"] < 13){
			setStatus("error");
			setMessage("Las facturas seleccionadas no se han marcado como completadas.");
		}else{
			setLoading(true);
			const ruta = "cambiar-estatus-multiple";
			const cambiar = await insertData(ruta,lista);
			if (isSubscribed) {
				if(cambiar.status === "success"){
					setCurrentTab(10);
					setStatus("success");
					setMessage("Se han transferido con exito.");
				}else{
					setStatus("error");
					setMessage("Ha ocurrido un error. Por favor intente de nuevo");
					setLoading(false);
				}
			}
		}
	}

	const [tab, setTab] = useState(11);
	
	const setCurrentTab = async(id) =>{
		if(!loading){
			setMessage("");
			setStatus("ok")
			setLoading(true);
			setTab(id);
			if(id===11){
				setLista([]);
				await getListaCompletados(id, 0);
				await addListaCompletados(id+1, 0);
				await addListaCompletados(id+2, 0);
			}else if(id===10){
				setLista([]);
				await getListaCompletados(id+1, 1);
				await addListaCompletados(id+2, 1);
				await addListaCompletados(id+3, 1);
			}else if(id===37 || id === 38){
				setLista([]);
				await getLista(id + "," + (id+1) + "," + (id+3) + "," + (id+4));
			}else if(id===16){
				await getLista(id);
			}
			if(isSubscribed){
				setLoading(false);
			}
		}
	}

	useEffect(() => { 
		// eslint-disable-next-line
		setCurrentTab(11);
		getDatosExcel();
		// eslint-disable-next-line
    	return () => {isSubscribed=false};
	}, []);

	const headers = [
	  { label: "ID Factura", key: "id_factura" },
	  { label: "N° Factura", key: "numero_factura" },
	  { label: "Fecha Factura", key: "fecha_factura" },
	  { label: "Folio SAT", key: "folio_sat" },
	  { label: "N° Remision", key: "no_remision" },
	  { label: "Fecha Remision", key: "fecha_remision" },
	  { label: "Cliente", key: "cliente" },
	  { label: "Consignatario", key: "consignatario" },
	  { label: "Area Fact.", key: "area_facturacion" },
	  { label: "Vendedor", key: "vendedor" },
	  { label: "Zona", key: "zona" },
	  { label: "Ruta", key: "ruta" },
	  { label: "Tipo de Pago", key: "tipo_pago" },
	  { label: "Subtotal", key: "subtotal" },
	  { label: "IVA", key: "iva" },
	  { label: "Total", key: "total" },
	  { label: "Tipo", key: "tipo_almacen" },
	  { label: "Tipo Documento", key: "tipo_documento" },
	  { label: "Empresa", key: "empresa" },
	  { label: "N° Pedido", key: "numero_pedido" },
	  { label: "Estatus", key: "estatus" },
	  { label: "Fecha de pago", key: "fecha_pago" },
	  { label: "Vencimiento", key: "vencimiento" },
	  { label: "Metodo de pago", key: "metodo_pago" },
	  { label: "Banco", key: "banco" },
	  { label: "Folio Microsip", key: "folio_microsip" },
	  { label: "Fecha de entrega", key: "fecha_entrega" },
	];

	return (
		<div className="card">
				<div className="container-fluid px-4">
					<ul className="nav nav-tabs tabs-navigate">
						<div className="w-100 text-right">
							{empty === false ? data.length > 0 ? <CSVLink data={data} headers={headers} 
							  className="btn btn-primary btn-round"
							  asyncOnClick={true}
							  filename={"Reporte CC.csv"}
							  onClick={(event, done) => {
							    getDatosExcel().then(() => {
							      done(); // REQUIRED to invoke the logic of component
							    });
							  }}
							  >
							  Descargar Excel
							</CSVLink>
								: <button className="btn btn-round btn-secondary"> Cargando... </button> : <p className="p-2">No es posible generar un reporte (no hay facturas en el sistema)</p>}
						</div>
						<li><a className={ tab===11 ? "active" : ""} onClick={ () =>{setCurrentTab(11)}} href="#tabs">Facturas Completas</a></li>
						<li><a className={ tab===10 ? "active" : ""} onClick={ () =>{setCurrentTab(10)}} href="#tabs">Remisiones Completas</a></li>
						<li><a className={ tab===16 ? "active" : ""} onClick={ () =>{setCurrentTab(16)}} href="#tabs">Remisiones Facturadas</a></li>
						<li><a className={ tab===37 ? "active" : ""} onClick={ () =>{setCurrentTab(37)}} href="#tabs">Pendientes por Pagar</a></li>
						<li><a className={ tab===38 ? "active" : ""} onClick={ () =>{setCurrentTab(38)}} href="#tabs">Pagadas</a></li>
					</ul>
					<div className="tab-content">
						<div id="surtir" className="tab-pane fade in active show">
							<div className="card-body">

								<LoadingMsg loading={loading} />
								<StatusMsg msg={message} status={status} />

								{ tab===11 && loading===false ? <Completas data={lista} getLista={setCurrentTab} cambiarStatus={cambiarStatus} /> : null }
								{ tab===16 && loading===false ? <RemisionesFacturadas data={lista} getLista={setCurrentTab} /> : null }
								{ tab===10 && loading===false ? <RemisionesCompletas data={lista} getLista={setCurrentTab} cambiarStatus={cambiarStatus} mandarRemisiones={mandarRemisiones} setStatus={setStatus} setMessage={setMessage} /> : null }
								{ tab===37 && loading===false ? <Pendientes data={lista} getLista={setCurrentTab} cambiarStatus={cambiarStatus} /> : null }
								{ tab===38 && loading===false ? <Pagadas data={lista} getLista={setCurrentTab} cambiarStatus={cambiarStatus} /> : null }
							</div>
						</div>
					</div>
				</div>
		</div>
	);
}

export default ListaFacturasCredito;