import React, {useState,useEffect} from 'react';
import {getData,updateItemObj,insertData,deleteItem} from '../tools/Helper';
import Botones from './Botones';
import NuevoUsuario from './NuevoUsuario';
import LoadingMsg from '../tools/LoadingMsg';
import StatusMsg from '../tools/StatusMsg';

import { useAuth } from "../../context/auth";

function Usuarios() {

	const { deleteTokens } = useAuth();

	function logOut() {
		deleteTokens();
	}

	let isSubscribed = true;

	const [usuarios, setUsuarios] = useState([]);
	const [id, setId] = useState([]);

	const [message, setMessage] = useState("");
	const [status, setStatus] = useState("");
	const [loading, setLoading] = useState("");


	const getUsuarios = async() =>{
		setLoading(true);
		const data = await getData('usuarios');
		if (isSubscribed) {
			if(data.status==="success"){
				setUsuarios(data.datos);
				console.log(data);
			}
			setLoading(false);
		}
	}

	useEffect(() => { 
		getUsuarios();
		// eslint-disable-next-line
    	return () => {isSubscribed=false};
  	},[]);

  	const editarLista = async() =>{
  		if(id[2].nombre_completo.length>0 && id[2].usuario.length>0){
	  		setMessage("");
			setStatus("ok");
	  		setLoading(true);
	  		let ruta = "usuarios/";

	  		const editar = await updateItemObj(ruta + id[0], id[2]);
	  		if (isSubscribed) {
	  			await getUsuarios();
	  			if(editar.status === "success"){
					setStatus("success");
					setMessage("Se ha editado con exito.");
				}else{
					setStatus("error");
					setMessage("Ha ocurrido un error. Por favor intente de nuevo");
				}
			setLoading(false);
	  		}
  		} else{
  			setMessage("Los campos Nombre y Usuario son requeridos");
			setStatus("error");
  		}
	}

	const deleteLista = async() =>{
		setMessage("");
		setStatus("ok");
  		setLoading(true);
  		let ruta = "usuarios/";
  		const eliminar = await deleteItem(ruta + id[0]);
  		if (isSubscribed) {
  			await getUsuarios();
  			if(eliminar.status === "success"){
				setStatus("success");
				setMessage("Se ha eliminado con exito.");
			}else{
				setStatus("error");
				setMessage("Ha ocurrido un error. Por favor intente de nuevo");
			}
		setLoading(false);
  		}
	}

	useEffect(() => { 
		if(id[1]===true){
			console.log("do editar ");
			editarLista();
		}else if(id[1]===false){
			console.log("do eliminar")
			deleteLista();
		}
  	},[id]);


	const agregarUsuario = async(agregar)=>{
		if(agregar.nombre_completo.length>0 && agregar.usuario.length>0 && agregar.password.length>0){
			setMessage("");
			setStatus("ok");
  			setLoading(true);
			let ruta = "usuarios";

			const add = await insertData(ruta, agregar);
			if (isSubscribed) {
				await getUsuarios();
				if(add.status === "success"){
					await setStatus("success");
					await setMessage("Se se ha creado con exito el usuario.");
				}else{
					setStatus("error");
					setMessage("Ha ocurrido un error. Por favor intente de nuevo");
				}
			setLoading(false);
			}
		}else{
  			setMessage("Por favor rellene todos los campos");
			setStatus("error");
  		}
	}

  	const listaUsuarios = usuarios ? usuarios.map((user) =>
	  <li className="w-50 d-inline" key={user.id_usuario} id={user.id_usuario}>
		<p><strong>Nombre:</strong> {user.nombre_completo}</p>
		<strong>Usuario:</strong> {user.usuario}
		<Botones 
		  	descripcion={user.nombre}
		  	id={user.id}
		  	setId={setId} 
		  	nombre={user.nombre_completo}
		  	usuario={user.usuario}
		/>
	  </li>)
	 : "Cargando...";

	return (
	<div className="content">
		<div className="row">
			<div className="col-md-12 pt-2">
				<div className="card">
					<div className="container-fluid px-4">
						<div className="card-body">
							<LoadingMsg loading={loading} />
							<StatusMsg msg={message} status={status} />

							{loading===false ? 
							<div className="row">
								<div className="col-6">
									<h3 className="mb-1">Agregar Usuario</h3>
									<NuevoUsuario tipo={1} agregarUsuario={agregarUsuario} />
								</div>
								<div className="col-6">
									<h3 className="mb-1">Usuarios del sistema</h3>
									<ol>{listaUsuarios}</ol>
								</div>
							</div> : ""}				
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	);
}

export default Usuarios;