import React from 'react';

const Formulario = ({firmas, status, loading}) =>{

    if(loading === false){
	    return(
			<div class="time">
				<div class="timeline">
					{
						firmas ? 
						Object.keys(firmas).map((item, i) => (
						<div class="entry">
							<div class="title">
								<h3>{firmas[item].fecha}</h3>
								<p>{firmas[item].departamento}</p>
							</div>
							<div class="body">
								<p className="mb-2"><span className="font-weight-bold">Firma:</span> {firmas[item].firma}</p>
								<p><span className="font-weight-bold">Comentarios:</span> {firmas[item].comentario === null ? "Ninguno" : firmas[item].comentario }</p>
								<ul>
									<li>Firmado por: {firmas[item].nombre}</li>
								</ul>
							</div>
						</div>
				    	)) : "No se encontraron firmas. Por favor intente de nuevo"
				    }
				</div>
			</div>
	    );
    }
    return("");
}

export default Formulario;