import React, {useState} from 'react';

function NuevoUsuario({tipo,agregarUsuario}) {

	const [datos, setDatos] = useState({nombre_completo: '', usuario: '', password: ''});

	const handleChange = e =>{
		setDatos({
			...datos,
			[e.target.name]: e.target.value
		})
	}

  	const setData = () =>{
  		agregarUsuario(datos);
  	}

	return (
		<React.Fragment>
			<p className="mb-0 pt-2">Nombre: <input type="text" className="form-control" onChange={handleChange} name="nombre_completo" value={datos.nombre_completo} /></p>
			<p className="mb-0 pt-2">Usuario: <input type="text" className="form-control" onChange={handleChange} name="usuario" value={datos.usuario} /></p>
			<p className="mb-0 pt-2">Contraseña: <input type="password" className="form-control" onChange={handleChange} name="password" value={datos.password} /></p>
				<button className="btn btn-small btn-success" onClick={setData}>Aceptar</button> 
  		</React.Fragment>
	);
}

export default NuevoUsuario;