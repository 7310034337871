import React, {useEffect} from 'react'

import Capturadas from './listas/Capturadas';
import EnAlmacen from './listas/EnAlmacen';
import Incompletas from './listas/Incompletas';
import Canceladas from './listas/Canceladas';
import Devoluciones from './listas/Devoluciones';
import Completas from './listas/Completas';
import PorEntregar from './listas/PorEntregar';
import ParaTramite from './listas/ParaTramite';
import RemisionesRecibidas from './listas/RemisionesRecibidas';
import Corte from './listas/Corte';

import StatusMsg from '../tools/StatusMsg';
import LoadingMsg from '../tools/LoadingMsg';
import {updateItem,updateItemObj,insertData} from '../tools/Helper';

//import { useAuth } from "../../context/auth";


const ListaFacturas = ({lista,loading,status,message,setCurrentTab, setStatus, setLoading, setMessage, tab, setFechaCorte, fechaCorte}) => {

	let isSubscribed = true;

	const cambiarStatus = async(listaFacturas, id, idLista) =>{
		setLoading(true);
		if(listaFacturas.length<1){
			const ruta = "cambiar-estatus/"+id;

			const cambiar = await updateItem(ruta);
			if (isSubscribed) {
				if(cambiar.status === "success"){
					setCurrentTab(idLista);
					setStatus("success");
					setMessage("Se ha transferido con exito.");
				}else{
					setStatus("error");
					setMessage("Ha ocurrido un error. Por favor intente de nuevo");
				}
			}
		}else{
			const ruta = "cambiar-estatus-multiple";

			const cambiar = await insertData(ruta, {facturas: listaFacturas});
			if (isSubscribed) {
				if(cambiar.status === "success"){
					setCurrentTab(idLista);
					setStatus("success");
					setMessage("Se ha transferido con exito.");
				}else{
					setStatus("error");
					setMessage("Ha ocurrido un error. Por favor intente de nuevo");
				}
			}
		}
		setLoading(false);
	}

	const cambiarStatusObj = async(id, idLista, status) =>{
		setLoading(true);
		setStatus("ok");
		setMessage("");

		const cambiar = await updateItemObj("cambiar-estatus/" + id, {id_estatus:11});
		if (isSubscribed) {
			if(cambiar.status === "success"){
				setStatus("success");
				setMessage("Se ha transferido con exito.");
				setCurrentTab(idLista);
			}else{
				setStatus("error");
				setMessage("Ha ocurrido un error.");
			}

			setLoading(false);
		}
	}

	useEffect(()=>{
		// eslint-disable-next-line
		setCurrentTab(1);
		// eslint-disable-next-line
		return () => {isSubscribed=false};
	},[])

	return (
		<div id="tabs" className="card">
			<div className="container-fluid px-4">
				<ul className="nav nav-tabs tabs-navigate">
					<li><a className={ tab===1 ? "active" : ""} onClick={ () =>{setCurrentTab(1)}} href="#tabs">Capturadas</a></li>
					<li><a className={ tab===2 ? "active" : ""} onClick={ () =>{setCurrentTab(2)}} href="#tabs">En Almacen</a></li>
					<li><a className={ tab===9 ? "active" : ""} onClick={ () =>{setCurrentTab(9)}} href="#tabs">En ruta</a></li>
					<li><a className={ tab===17 ? "active" : ""} onClick={ () =>{setCurrentTab(17)}} href="#tabs">Para Trámite</a></li>
					<li><a className={ tab===14 ? "active" : ""} onClick={ () =>{setCurrentTab(14)}} href="#tabs">Remisiones Recibidas</a></li>
					<li><a className={ tab===21 ? "active" : ""} onClick={ () =>{setCurrentTab(21)}} href="#tabs">Incompletas</a></li>
					<li><a className={ tab===29 ? "active" : ""} onClick={ () =>{setCurrentTab(29)}} href="#tabs">Canceladas</a></li>
					<li><a className={ tab===25 ? "active" : ""} onClick={ () =>{setCurrentTab(25)}} href="#tabs">Devoluciones</a></li>
					<li><a className={ tab===13 ? "active" : ""} onClick={ () =>{setCurrentTab(13)}} href="#tabs">Completas</a></li>
					<li className="ml-auto"><a className= { tab===18 ? "active" : ""} onClick={ () =>{setCurrentTab(18)}} href="#tabs">Corte del dia</a></li>
				</ul>
				<div className="tab-content">
					<div id="capturadas" className="tab-pane fade in active show">
						<div className="card-body">

							<LoadingMsg loading={loading} />
							<StatusMsg status={status} msg={message}/>

							{ tab===1 && loading === false ? <Capturadas data={lista} cambiarStatus={cambiarStatus} getLista={setCurrentTab} /> : null }
							{ tab===2 && loading === false ? <EnAlmacen data={lista}  getLista={setCurrentTab} /> : null }
							{ tab===9 && loading === false ? <PorEntregar data={lista} getLista={setCurrentTab} /> : null }
							{ tab===14 && loading === false ? <RemisionesRecibidas data={lista} getLista={setCurrentTab} /> : null }
							{ tab===17 && loading === false ? <ParaTramite data={lista} getLista={setCurrentTab} /> : null }
							{ tab===21 && loading === false ? <Incompletas data={lista} cambiarStatus={cambiarStatus} getLista={setCurrentTab} /> : null }
							{ tab===29 && loading === false ? <Canceladas data={lista} cambiarStatus={cambiarStatus} getLista={setCurrentTab} /> : null }
							{ tab===25 && loading === false ? <Devoluciones data={lista} cambiarStatus={cambiarStatus} getLista={setCurrentTab} /> : null }
							{ tab===13 && loading === false ? <Completas data={lista} cambiarStatus={cambiarStatus} getLista={setCurrentTab} /> : null }
							{ tab===18 && loading === false ? <Corte data={lista} cambiarStatus={cambiarStatusObj} getLista={setCurrentTab} setFechaCorte={setFechaCorte} fechaCorte={fechaCorte} /> : null }
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ListaFacturas;