import React from 'react';

function EntregaCompleta({numero}) {
  return (
    <div className="card card-stats">
      <div className="card-body ">
        <div className="row">
          <div className="col-5 col-md-4">
            <div className="icon-big text-center icon-warning">
              <i className="nc-icon nc-check-2 text-success"></i>
            </div>
          </div>
          <div className="col-7 col-md-8">
            <div className="numbers">
              <p className="card-category">Entrega Completa</p>
              <p className="card-title">{numero ? numero : "0"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer ">
        <hr />
        <div className="stats">
          Factura
        </div>
      </div>
    </div>
  );
}

export default EntregaCompleta;