import React, {useState, useEffect} from 'react'
import Input from '../formComponents/Input';
import InputGroupTipoPago from '../formComponents/InputGroupTipoPago';

import {insertData} from '../tools/Helper';
import LoadingMsg from '../tools/LoadingMsg';
import StatusMsg from '../tools/StatusMsg';
import {getData} from '../tools/Helper';

import DateTimePicker from 'react-datetime-picker';

import ComentarioModal from './ComentarioModal';
import ModalRemisiones from './ModalTramites/ModalRemisiones';

import { format } from 'date-fns';

const NuevaFactura =({getLista})=>{

	const [catalogo, setCatalogo] = useState([]);

	let isSubscribed = true;

	const getTipos = async() =>{
		const data = await getData('catalogos');
		if (isSubscribed) {
			if(data.status==="success"){
				setCatalogo(data);
				console.log(data)
				const tipoPago = data.pago[0];
				const tipoAlmacen = data.almacen[0];
				const tipoDocumento = data.documento[0];

				setFactura({
					numero_factura: '',
					folio_sat: '',
					fecha_factura: '',
					cliente: '',
					consignatario: '',
					area_facturacion: '',
					vendedor: '',
					zona: '',
					subtotal: '',
					iva: '',
					total: '',
					empresa: '',
					numero_pedido: '',
					datos_estatus: '',
					id_estatus: 1,
					comentario: '',
					'id_tipo_documento': tipoDocumento.id_tipo_documento,
					'id_tipo_pago': tipoPago.id_tipo_pago,
					'id_tipo_almacen': tipoAlmacen.id_tipo_almacen
				})
			}
		}
	}

	const [fecha, setFecha] = useState(new Date());
	const [fechaToString, setFechaToString] = useState(fecha.getFullYear() + '-' + ((fecha.getMonth()+1) < 10 ? '0' : '') + (fecha.getMonth()+1) + '-' + 
			 (fecha.getDate() < 10 ? '0' : '') + fecha.getDate() + ' ' + 
			 (fecha.getHours() < 10 ? '0' : '') + fecha.getHours() + ':' + 
			 (fecha.getMinutes() < 10 ? '0' : '') + fecha.getMinutes() + ':00');

	const [factura, setFactura] = useState({
		numero_factura: '',
		folio_sat: '',
		fecha_factura: '',
		cliente: '',
		consignatario: '',
		area_facturacion: '',
		vendedor: '',
		zona: '',
		subtotal: '',
		iva: '',
		total: '',
		empresa: '',
		numero_pedido: '',
		id_estatus: 1,
		comentario: '',
	});

	const change = fecha => {
		setFecha(fecha)
	};

	const [status, setStatus] = useState("ok");
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);

	const handleChange = e =>{
		setFactura({
			...factura,
			[e.target.name]: e.target.valueAsNumber || e.target.value
		})
	}

	const handleChangeTipo = e =>{

		const selectedIndex = e.target.options.selectedIndex;
		const id = parseInt(e.target.options[selectedIndex].getAttribute('id'));
		setFactura({
			...factura,
			[e.target.name]: id
		})
	}

	useEffect(() => { 
		setFechaToString(fecha.getFullYear() + '-' + ((fecha.getMonth()+1) < 10 ? '0' : '') + (fecha.getMonth()+1) + '-' + 
			 (fecha.getDate() < 10 ? '0' : '') + fecha.getDate() + ' ' + 
			 (fecha.getHours() < 10 ? '0' : '') + fecha.getHours() + ':' + 
			 (fecha.getMinutes() < 10 ? '0' : '') + fecha.getMinutes() + ':00');
	}, [fecha]);

	useEffect(() => { 
		setFactura(factura => ({...factura, fecha_factura:fechaToString}));
	}, [fechaToString]);

	useEffect(() => { 
		getTipos();
		// eslint-disable-next-line
	   	return () => {isSubscribed=false};
	}, []);

	useEffect(() => { 
			if(factura.id_tipo_documento === 4){
				setTramite(true);
			}else if(factura.id_tipo_documento === 7){
				setTramite(false);
				handleShow();
			}else{
				setTramite(false);
				setFactura(factura => ({...factura, comentario:''}));
			}
	}, [factura.id_tipo_documento]);

	const submitCategoria = async e =>{
		e.preventDefault();

		console.log(JSON.stringify(factura));

		setLoading(true);
		
		const nuevaFactura = await insertData('facturas',factura);
		if (isSubscribed) {
			console.log(nuevaFactura);

			if(nuevaFactura.status === "error"){
				setStatus("error");
				setMessage("Faltan campos por rellenar.");
			}else if(nuevaFactura.status === "success"){
				setStatus("success");
				setMessage("Factura capturada con exito.");
				await getTipos();
				getLista(1);
			}
			setLoading(false);
		}
	}

	const [showInfo, setShowInfo] = useState(false);

	const setComentario = e =>{
		setFactura({
			...factura,
			comentario: e.target.value
		})
	}

	const handleShow = () =>{
		setShowInfo(true);
	}

	const [tramite, setTramite] = useState(false);

	const handleCloseInfo = () => {
		setFactura({
			...factura,
			comentario: ''
		})
		setShowInfo(false);
	}	

	const aceptarInfo = (status) => {
		setShowInfo(false);
	}	

	/* MODAL REMISIONES */
	const [showRemisiones, setShowRemisiones] = useState(false);
	const openRemisiones = e => {
		e.preventDefault();
		setShowRemisiones(true);
	}
	const handleCloseRemisiones = () => {
		setShowRemisiones(false);
	}	

	const setRemisiones = (lista) => {
		setFactura({
			...factura,
			remisiones: lista
		})
		setShowRemisiones(false);
	}	
	/* --- */

	return (
	<div className="card card-user">
		<div className="card-header">
			<h4 className="card-title">Nueva Factura</h4>
		</div>
		<div className="card-body">
			<LoadingMsg loading={loading} />
			<StatusMsg status={status} msg={message}/>
			<form>
				<div className="row">
					<Input label="ID" placeholder="ID" type="text" colSize="3"/>
					<Input label="No. Factura" placeholder="No. Factura" type="text" colSize="3" onChange={handleChange} name="numero_factura" value={factura["numero_factura"]} />
					<Input label="Folio" placeholder="Folio" type="text" colSize="3" onChange={handleChange} name="folio_sat" value={factura["folio_sat"]} />
					<Input label="No. Pedido" placeholder="No. Pedido" type="text" colSize="3" onChange={handleChange} name="numero_pedido" value={factura["numero_pedido"]} />
				</div>
				<div className="row">
					<Input label="Fecha" placeholder="Fecha" type="text" colSize="4" onChange={handleChange} name="fecha_factura" value={factura["fecha_factura"]} />
					<InputGroupTipoPago label="Tipo Almacen" options={catalogo.almacen} colSize="4" onChange={handleChangeTipo} name="id_tipo_almacen" value={factura["id_tipo_almacen"]} type={3} />
					<Input label="No. Remisión" placeholder="No. Remision" type="text" colSize="4" onChange={handleChange} name="no_remision" value={factura["no_remision"]} />
				</div>
				<div className="row">
					<Input label="Consignatario" placeholder="Consignatario" type="text" colSize="12" onChange={handleChange} name="consignatario" value={factura["consignatario"]} />
					<Input label="Cliente" placeholder="Cliente" type="text" colSize="12" onChange={handleChange} name="cliente" value={factura["cliente"]} />
				</div>
				<div className="row">
					<Input label="Empresa" placeholder="Empresa" type="text" colSize={tramite? "5" : "8"} onChange={handleChange} name="empresa" value={factura["empresa"]} />
					<InputGroupTipoPago label="Tipo Documento" options={catalogo.documento} colSize="4" onChange={handleChangeTipo} name="id_tipo_documento" value={factura["id_tipo_documento"]} type={1} />
					{tramite ? <div className="col-md-3">
						<div className="button-center">
							<button className="btn btn-primary btn-round" onClick ={openRemisiones}>Seleccionar Remisiones</button>
						</div>
					</div> : ""}
				</div>
				<div className="row">
					<Input label="Zona" placeholder="Zona" type="text" colSize="4" onChange={handleChange} name="zona" value={factura["zona"]} />
					<Input label="Ruta" placeholder="Ruta" type="text" colSize="4" onChange={handleChange} name="ruta" value={factura["ruta"]} />
					<InputGroupTipoPago label="Tipo de Pago" options={catalogo.pago} colSize="4" onChange={handleChangeTipo} name="id_tipo_pago" value={factura["id_tipo_pago"]} type={2} />
				</div>
				<div className="row">
					<Input label="Área de facturación" placeholder="Area de facturación" type="text" colSize="6" onChange={handleChange} name="area_facturacion" value={factura["area_facturacion"]} />
					<Input label="Vendedor" placeholder="Vendedor" type="text" colSize="6" onChange={handleChange} name="vendedor" value={factura["vendedor"]} />
				</div>
				<div className="row">
					<Input label="Subtotal" placeholder="Subtotal" type="number" colSize="4" onChange={handleChange} name="subtotal" value={factura["subtotal"]} />
					<Input label="IVA" placeholder="IVA" type="number" colSize="4" onChange={handleChange} name="iva" value={factura["iva"]} />
					<Input label="Total" placeholder="Total" type="number" colSize="4" onChange={handleChange} name="total" value={factura["total"]} />
				</div>
				<div className="row">
					<div className="update ml-auto mr-auto">
						<button className="btn btn-primary btn-round" onClick ={submitCategoria}>GUARDAR FACTURA</button>
					</div>
				</div>
			</form>
		</div>
		<ComentarioModal 
			show={showInfo}
			handleClose={handleCloseInfo}
			comentario={factura["comentario"]}
			setComentario={setComentario}
			aceptarInfo={aceptarInfo}
		/>
		<ModalRemisiones 
			show={showRemisiones}
			handleClose={handleCloseRemisiones}
			setRemisiones={setRemisiones}
		/>
	</div>
);

}

export default NuevaFactura;