import React, {useState,useEffect} from 'react';
import DataTable from 'react-data-table-component';
import Firma from '../../firmar/Firma';
import Captura from '../../firmar/CapturarRemision/Captura';
import BotonFirmar from '../../firmar/BotonFirmar';

import BotonInfo from '../../timeline/BotonInfo';
import ModalFirmas from '../../timeline/ModalFirmas';
import Input from '../../formComponents/Input';

import {customStyles, paginacionOpciones, Empty, columnasData} from '../../tools/TableStyles';

const RemisionesCompletas =({data,cambiarStatus,getLista,mandarRemisiones,setStatus,setMessage})=>{

	const [clearRows, setClearRows] = useState(false);

	/* DATOS DE MODAL PARA FIRMAR */
	const [factura, setFactura] = useState([]);
	const [show, setShow] = useState(false);
	const [reload, setReload] = useState(false);
	const [titulo, setTitulo] = useState("Firmar como Jefe de Credito y Cobranza");

	const setItem = (id,estatus,departamento,titulo,listaFacturas) =>{
		setTitulo(titulo);
		setFactura({id_factura:id,id_estatus:estatus,id_departamento:departamento, facturas:listaFacturas});
		handleShow(true);
		console.log(factura);
	}

	const handleShow = () => setShow(true);
	const handleClose = (status) => {
		setShow(false)
		if(reload===true){
			getLista(10);
		}
	};
	/* --- */

	/* DATOS DE MODAL PARA CAPTURAR */
	const [showCaptura, setShowCaptura] = useState(false);

	const setItemCaptura = (id,estatus,departamento,titulo,listaFacturas) =>{
		setFactura({id_factura:id});
		handleShowCaptura(true);
		console.log(factura);
	}

	const handleShowCaptura = () => setShowCaptura(true);
	const handleCloseCaptura = (status) => {
		setShowCaptura(false)
		if(reload===true){
			getLista(10);
		}
	};
	/* --- */

	/* MODAL DE INFORMACION DE FIRMAS*/
	const [showInfo, setShowInfo] = useState(false);
	const [selectedItemInfo, setSelectedItemInfo] = useState(0);

	const setItemInfo = (id) =>{
		setSelectedItemInfo(id);
		setShowInfo(true);
	}

	const handleCloseInfo = (status) => setShowInfo(false);
	/* --- */

	const completar = (state) => {
		console.log('clicked');
		console.log(state.target.id);
		cambiarStatus(facturasArray,state.target.id,10);
	};

	/* SELECCION DE MULTIPLES FACTURAS */
	const [hideRowStyle, setHideRowStyle] = useState([{when: row => row.id, style:{}},{when: row => row.id, style:{}}]);
	const [facturasArray, setFacturasArray] = useState([]);

	const selectedChange = (state) => {
	  setFacturasArray(state.selectedRows);
	};

	useEffect(() => { 
		if(facturasArray.length>0){
			setHideRowStyle(hideRowStyle =>([
						  {
						    when: row => row.id_estatus !== facturasArray[0]['id_estatus'],
						    style: {
						    	display:'none',
						    },
						  },
						  hideRowStyle[1]
						]));
		}else{
			setHideRowStyle(hideRowStyle =>([
				{when: row => row.id, style:{}},
				hideRowStyle[1]
				]));
		}
	}, [facturasArray]);
	/* --- */

	/* BUSQUEDA DE FACTURAS POR NO. PEDIDO */
	const handleChange = e =>{
		setBusqueda(e.target.value)
		console.log(e.target.value);
	}
	
	const [busqueda, setBusqueda] = useState("");

	useEffect(() => { 
		setClearRows(clearRows =>(!clearRows));
		if(busqueda.length>0){
			setHideRowStyle(hideRowStyle =>([
			  hideRowStyle[0],
			  {
			  	when: row => !row.numero_pedido.startsWith(busqueda),
			    style: {
			    	display:'none',
			    },
			  },
			]));
		}else{
			setHideRowStyle(hideRowStyle =>([
				hideRowStyle[0],
				{when: row => row.id, style:{}}
			]));
		}
	}, [busqueda]);
	/* --- */

	/* MANDAR LISTA DE REMISIONES */
	const mandarFacturas = () =>{
		if(facturasArray.length>0){
			setStatus("ok");
			setMessage("");
			mandarRemisiones({facturas:facturasArray});
		}else{
			setStatus("error");
			setMessage("No ha seleccionado ninguna factura.");
		}
	}
	
	/* --- */

	const columnas = [
		{
			name: 'ACCIONES',
			cell: (row) => 
			Number.parseInt(row.id_estatus, 10) === 11 ? 
			<BotonFirmar id={row.id_factura} estatus={row.id_estatus} departamento={row.id_departamento}
				setItem={setItem} text="Firmar" type={"1"} facturas={facturasArray} /> : 
			Number.parseInt(row.id_estatus, 10) === 12 ? 
			<button className="btn btn-success btn-round btn-sm" onClick={completar} id={row.id_factura}>completar</button> : 
			<button className="btn btn-round btn-sm" disabled>COMPLETADO</button>,
			ignoreRowClick: true,
			compact:true,
			minWidth:"7rem"
		},
		{
			name: '',
			cell: (row) => 
			Number.parseInt(row.id_estatus, 10) === 11 ? "" : 
			Number.parseInt(row.id_estatus, 10) === 12 ? 
			<BotonFirmar id={row.id_factura} estatus={row.id_estatus} departamento={row.id_departamento}
				setItem={setItem} text="INCOMPLETA" type={5} facturas={facturasArray} /> : 
			row.id_captura === null ? 
				<BotonFirmar id={row.id_factura} estatus={row.id_estatus} departamento={row.id_departamento}
				setItem={setItemCaptura} text="CAPTURAR" type={"3"} facturas={facturasArray} /> : <button className="btn btn-round btn-sm" disabled>CAPTURADO</button>,
			ignoreRowClick: true,
			compact:true,
			minWidth:"7.2rem"
		},
		{
			name: '',
			cell: (row) => <BotonInfo id={row.id_factura} setItem={setItemInfo} />,
			ignoreRowClick: true,
			compact:true,
			width:"1.8rem"
		},
	]

	return(
		<React.Fragment>
			<div className="row">
				<div className="col-md-6">
					<Input label="Busqueda por No. Pedido/Cotización" placeholder="No. pedido" type="text" colSize="12" onChange={handleChange} name="numero_pedido" value={busqueda} />
				</div>
				<div className="col-md-3">
					<div className="button-center">
						<button className="btn btn-primary btn-round btn-sm" onClick ={mandarFacturas}>Mandar lista a tramite</button>
					</div>
				</div>
			</div>
			<DataTable
				columns= {[...columnasData, ...columnas]}
				title = "Facturas Completas"
				data={data}
				pagination
				paginationComponentOptions = {paginacionOpciones}
				fixedHeader
				customStyles={customStyles}
				noDataComponent=<Empty />
				selectableRows
				selectableRowsNoSelectAll
				onSelectedRowsChange={selectedChange}
				conditionalRowStyles={hideRowStyle}
				clearSelectedRows={clearRows}
			/>
			<Firma 
				show={show} 
				handleClose={handleClose}
				titulo={titulo}
				reload={reload}
				getLista={getLista}
				setReload={setReload}
				factura={factura}
			/>
			<Captura 
				show={showCaptura} 
				handleClose={handleCloseCaptura}
				reload={reload}
				getLista={getLista}
				setReload={setReload}
				factura={factura}
				facturasArray={facturasArray}
			/>
			<ModalFirmas 
				id={selectedItemInfo} 
				show={showInfo}
				handleClose={handleCloseInfo}
			/>
		</React.Fragment>
	)
}

export default RemisionesCompletas;