import React, {useState,useEffect} from 'react';
import DataTable from 'react-data-table-component';
import DateTimePicker from 'react-datetime-picker';

import {customStyles, paginacionOpciones, Empty, columnasData} from '../../tools/TableStyles';

import BotonInfo from '../../timeline/BotonInfo';
import ModalFirmas from '../../timeline/ModalFirmas';

import BotonComentarios from '../../timeline/BotonComentarios';
import ModalComentarios from '../../timeline/ModalComentarios';

const Corte =({data,cambiarStatus,setFechaCorte,fechaCorte})=>{
	
	/* COMPLETAR */
	const handleButtonClick = (state) => {
		cambiarStatus(state.target.id,18);
	};
	/* --- */

	/* MODAL DE INFORMACION DE FIRMAS*/
	const [showInfo, setShowInfo] = useState(false);
	const [selectedItemInfo, setSelectedItemInfo] = useState(0);

	const setItemInfo = async (id) =>{
		await setSelectedItemInfo(id);
		await setShowInfo(true);
	}

	const handleCloseInfo = (status) => setShowInfo(false);
	/* --- */

	/* SELECCION DE MULTIPLES FACTURAS */
	const [hideRowStyle, setHideRowStyle] = useState([]);
	const [facturasArray, setFacturasArray] = useState([]);

	const selectedChange = (state) => {
	  setFacturasArray(state.selectedRows);
	};

	useEffect(() => { 
		if(facturasArray.length>0){
			setHideRowStyle([
			  {
			    when: row => row.id_estatus !== facturasArray[0]['id_estatus'],
			    style: {
			    	display:'none',
			    },
			  },
			]);
		}else{
			setHideRowStyle([]);
		}
	}, [facturasArray]);
	/* --- */

	/* MODAL DE COMENTARIO REFACTURACION */
	const [showInfoComentario, setShowInfoComentario] = useState(false);
	const [comentario, setComentario] = useState("No se han introducido comentarios.")

	const showComentario = async (com) =>{
		if(com !== null){
			setComentario(com);
		}
		await setShowInfoComentario(true);
	}

	const closeComentario = (status) => setShowInfoComentario(false);
	/* --- */

	const columnas = [{
			name: 'ESTATUS',
			cell: (row) => 
				<span className="text-success font-weight-bold">{row.estatus}</span>,
			wrap: "true",
			grow: '1.3',
		},
		{
			name: 'ACCIONES',
			cell: (row) => (Number.parseInt(row.id_estatus, 10) === 10 || Number.parseInt(row.id_estatus, 10) === 36 || Number.parseInt(row.id_estatus, 10) === 39 || Number.parseInt(row.id_estatus, 10) === 42) ? 
			<button type="submit" className="btn btn-warning btn-round btn-sm" onClick={handleButtonClick} id={row.id_factura}>Enviar a C. y C.</button> : "",
			ignoreRowClick: true,
			grow: '1.3',
		},
		{
			name: '',
			cell: (row) => <div>
			<BotonInfo id={row.id_factura} setItem={setItemInfo} /> 
			{row.id_tipo_documento === 7 ? 
				<BotonComentarios comentario={row.comentario} setComentario={showComentario} /> : ""} 
			</div>,
			ignoreRowClick: true,
			compact:true,
			width:"1.8rem"
	}];

	return(
		<React.Fragment>
			<DateTimePicker
				onChange={setFechaCorte}
				value={fechaCorte}
				className="form-control c-padding"
				disableClock={true}
				name="fecha"
			/>
			<DataTable
				columns= {[...columnasData, ...columnas]}
				title = "Corte del dia"
				data={data}
				pagination
				paginationComponentOptions = {paginacionOpciones}
				fixedHeader
				customStyles={customStyles}
				noDataComponent=<Empty />
				selectableRows
				selectableRowsNoSelectAll
				onSelectedRowsChange={selectedChange}
				conditionalRowStyles={hideRowStyle}
			/>
			<ModalFirmas 
				id={selectedItemInfo} 
				show={showInfo}
				handleClose={handleCloseInfo}
			/>
			<ModalComentarios  
				show={showInfoComentario}
				handleClose={closeComentario}
				comentario={comentario}
			/>
		</React.Fragment>
	)
}

export default Corte;