import React, {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';
import Firma from '../../firmar/Firma';
import BotonFirmar from '../../firmar/BotonFirmar';
import {customStyles, paginacionOpciones, Empty, columnasData} from '../../tools/TableStyles';

import BotonInfo from '../../timeline/BotonInfo';
import ModalFirmas from '../../timeline/ModalFirmas';

import BotonComentarios from '../../timeline/BotonComentarios';
import ModalComentarios from '../../timeline/ModalComentarios';

const Cancelaciones =({data,getLista})=>{
	
	/* DATOS DE MODAL PARA FIRMAR */
	const [factura, setFactura] = useState([]);
	const [show, setShow] = useState(false);
	const [reload, setReload] = useState(false);
	const [titulo, setTitulo] = useState("Firmar como Jefe de Almacen");

	const setItem = async (id,estatus,departamento,titulo,listaFacturas) =>{
		await setTitulo(titulo);
		await setFactura({id_factura:id,id_estatus:estatus,id_departamento:departamento, facturas:listaFacturas});
		await handleShow(true);
		console.log(factura);
	}

	const handleShow = () => setShow(true);
	const handleClose = (status) => {
		setShow(false)
		if(reload===true){
			getLista(27);
		}
	};
	/* --- */

	/* MODAL DE INFORMACION DE FIRMAS*/
	const [showInfo, setShowInfo] = useState(false);
	const [selectedItemInfo, setSelectedItemInfo] = useState(0);

	const setItemInfo = async (id) =>{
		await setSelectedItemInfo(id);
		await setShowInfo(true);
	}
	const handleCloseInfo = (status) => setShowInfo(false);
	/* --- */

	/* SELECCION DE MULTIPLES FACTURAS */
	const [hideRowStyle, setHideRowStyle] = useState([]);
	const [facturasArray, setFacturasArray] = useState([]);

	const selectedChange = (state) => {
	  setFacturasArray(state.selectedRows);
	};

	useEffect(() => { 
		if(facturasArray.length>0){
			setHideRowStyle([
			  {
			    when: row => row.id_estatus !== facturasArray[0]['id_estatus'],
			    style: {
			    	display:'none',
			    },
			  },
			]);
		}else{
			setHideRowStyle([]);
		}
	}, [facturasArray]);
	/* --- */

	/* MODAL DE COMENTARIO REFACTURACION */
	const [showInfoComentario, setShowInfoComentario] = useState(false);
	const [comentario, setComentario] = useState("No se han introducido comentarios.")

	const showComentario = async (com) =>{
		if(com !== null){
			setComentario(com);
		}
		await setShowInfoComentario(true);
	}

	const closeComentario = (status) => setShowInfoComentario(false);
	/* --- */
	
	const columnas = [
		{
			name: 'FIRMAR',
			cell: (row) => 
			Number.parseInt(row.id_estatus, 10) === 27 || Number.parseInt(row.id_estatus, 10) === 31 ? 
			<BotonFirmar id={row.id_factura} estatus={row.id_estatus} departamento={row.id_departamento} 
				setItem={setItem} text="Firmar" type={1} facturas={facturasArray} /> : 
			<button type="submit" className="btn btn-round btn-sm" disabled>FIRMADO</button>,
			ignoreRowClick: true,
			compact:true,
			width:"7rem"
		},
		{
			name: '',
			cell: (row) => <div>
			<BotonInfo id={row.id_factura} setItem={setItemInfo} /> 
			{row.id_tipo_documento === 7 ? 
				<BotonComentarios comentario={row.comentario} setComentario={showComentario} /> : ""} 
			</div>,
			ignoreRowClick: true,
			compact:true,
			width:"1.8rem"
		},
	]

	return(
		<React.Fragment>
			<DataTable
				columns= {[...columnasData, ...columnas]}
				title = "Cancelaciones/Devoluciones"
				data={data}
				pagination
				paginationComponentOptions = {paginacionOpciones}
				fixedHeader
				customStyles={customStyles}
				noDataComponent=<Empty />
				selectableRows
				selectableRowsNoSelectAll
				onSelectedRowsChange={selectedChange}
				conditionalRowStyles={hideRowStyle}
			/>
			<p>* Firmar cuando haya registrado la nota de crédito.</p>
			<p>* Descuente el monto de la factura, 5 días para registrar la nota.</p>
			<Firma 
				show={show} 
				handleClose={handleClose}
				titulo={titulo}
				reload={reload}
				getLista={getLista}
				setReload={setReload}
				factura = {factura}
			/>
			<ModalFirmas 
				id={selectedItemInfo} 
				show={showInfo}
				handleClose={handleCloseInfo}
			/>
			<ModalComentarios  
				show={showInfoComentario}
				handleClose={closeComentario}
				comentario={comentario}
			/>
		</React.Fragment>
	)
}

export default Cancelaciones;