import React, {useState, useEffect} from 'react';

import Sidebar from './components/template/Sidebar';
import Navbar from './components/template/Navbar';
import Footer from './components/template/Footer';

import Dashboard from './components/dashboard/Dashboard'
import Facturacion from './components/facturacion/Facturacion'
import Almacen from './components/almacen/Almacen'
import Credito from './components/credito/Credito'
import Contabilidad from './components/contabilidad/Contabilidad'
import Consultas2 from './components/consultas/Consultas2'
import Catalogos from './components/catalogos/Catalogos'
import Usuarios from './components/usuarios/Usuarios'
import Login from './components/login/Login'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { AuthContext } from "./context/auth";
import PrivateRoute from './PrivateRoute';


function App(props){

  const [authTokens, setAuthTokens] = useState(localStorage.getItem('tokens') || '');

  const [selectedItem, setSeleccted] = useState(0);
  
  const setTokens = (data) => {
    localStorage.setItem('tokens', JSON.stringify(data));
    setAuthTokens(data);
  }

  const deleteTokens = () =>{
    localStorage.removeItem('tokens');
    setAuthTokens('');
  }

  useEffect(() => { 
      if(window.location.pathname==="/dashboard"){
        setSeleccted(0)
      }else if(window.location.pathname==="/facturacion"){
        setSeleccted(1)
      }else if(window.location.pathname==="/credito"){
        setSeleccted(2)
      }else if(window.location.pathname==="/contabilidad"){
        setSeleccted(3)
      }else if(window.location.pathname==="/almacen"){
        setSeleccted(4)
      }else if(window.location.pathname==="/consultas"){
        setSeleccted(5)
      }else if(window.location.pathname==="/catalogos"){
        setSeleccted(6)
      }else if(window.location.pathname==="/login"){
        setSeleccted(7)
      }
  }, [])

  return(

    <AuthContext.Provider value={{authTokens: authTokens, setAuthTokens: setTokens, deleteTokens: deleteTokens }}>

    <Router>
        <div className="wrapper">
          <Sidebar item={selectedItem} setItem={setSeleccted} />
            <div className="main-panel">
              <Navbar item={selectedItem} />
              <div className="content">
                <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute path="/dashboard"  component={Dashboard} />
                <PrivateRoute path="/facturacion"  component={Facturacion} />
                <PrivateRoute path="/almacen"  component={Almacen} />
                <PrivateRoute path="/credito"  component={Credito} />
                <PrivateRoute path="/contabilidad"  component={Contabilidad} />
                <PrivateRoute path="/consultas"  component={Consultas2} />
                <PrivateRoute path="/catalogos"  component={Catalogos} />
                <PrivateRoute path="/usuarios"  component={Usuarios} />
                <Route path="/login"  component={Login} />
                </Switch>
              </div>
              <Footer />
            </div>
        </div>
    </Router>

    </AuthContext.Provider>

  )
}

export default App;