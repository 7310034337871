import React from 'react';

export const customStyles = {
    rows: {
      style: {
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem' // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '15px', // override the cell padding for head cells
        paddingRight: '15px',
        fontWeight: '800',
      },
    },
    cells: {
      style: {
        paddingLeft: '15px', // override the cell padding for data cells
        paddingRight: '15px',
      },
    },
}

export const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
 }

 export const Empty =()=>{
    return(<div className="container text-center p-4">No se han encontrado facturas</div>);
 }

 export const columnasData = [
    {
      name: 'ID',
      selector:'id_factura',
      grow: "0",
    },
    {
      name: 'FACTURA',
      selector : 'numero_factura',
    },
    {
      name: 'FOLIO',
      selector : 'folio_sat',
    },
    {
      name: 'PEDIDO',
      selector : 'numero_pedido',
    },
    {
      name: 'CLIENTE',
      selector : 'cliente',
    },
    {
      name: 'FECHA',
      selector : row => row.id_tipo_documento === 2 ? row.fecha_remision : row.fecha_factura,
      wrap:true,
      grow:0.5,
      minWidth:"6rem"
    },
  ]