import React from 'react';
import { Link } from 'react-router-dom';

function App(props) {

  return (
    <div className="sidebar" data-color="white" data-active-color="danger">
      <div className="logo">
        <span className="simple-text logo-mini">
          <div className="logo-image-small">
            
          </div>
          
        </span>
        <span className="simple-text logo-normal">
          Dimetab
        </span>
      </div>
      <div className="sidebar-wrapper">
        <ul className="nav">
          <li className={ (props.item===0) ? "active" : "" }>
            <Link to="/dashboard" className="link" onClick={()=>props.setItem(0)}>
              <i className="nc-icon nc-chart-bar-32"></i>
              <p>Dashboard</p>
            </Link>
          </li>
          <li className={ (props.item===1) ? "active" : "" }>
            <Link to="/facturacion" className="link" onClick={()=>props.setItem(1)}>
              <i className="nc-icon nc-single-copy-04"></i>
              <p>Facturación</p>
            </Link>
          </li>
          <li className={ (props.item===2) ? "active" : "" }>
            <Link to="/credito" className="link" onClick={()=>props.setItem(2)}>
              <i className="nc-icon nc-circle-10"></i>
              <p>Crédito y Cobranza</p>
            </Link>
          </li>
          <li className={ (props.item===3) ? "active" : "" }>
            <Link to="/contabilidad" className="link" onClick={()=>props.setItem(3)}>
              <i className="nc-icon nc-money-coins"></i>
              <p>Contabilidad</p>
            </Link>
          </li>
          <li className={ (props.item===4) ? "active" : "" }>
            <Link to="/almacen" className="link" onClick={()=>props.setItem(4)}>
              <i className="nc-icon nc-box-2"></i>
              <p>Almacen</p>
            </Link>
          </li>
          <li className={ (props.item===5) ? "active" : "" }>
            <Link to="/consultas" className="link" onClick={()=>props.setItem(5)}>
              <i className="nc-icon nc-zoom-split"></i>
              <p>Consultas</p>
            </Link>
          </li>
          <li className={ (props.item===6) ? "active" : "" }>
              <Link to="/catalogos" className="link" onClick={()=>props.setItem(6)}>
              <i className="nc-icon nc-layout-11"></i>
              <p>Catálogos</p>
              </Link>
          </li>
          <li className={ (props.item===8) ? "active" : "" }>
            <Link to="/usuarios" className="link" onClick={()=>props.setItem(8)}>
              <i className="nc-icon nc-circle-10"></i>
              <p>Usuarios</p>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default App;