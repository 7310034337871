import React, {useState, useEffect} from 'react'

const StatusMsg =({msg,status})=>{
  const [label, setLabel] = useState('alert alert-default');
  
  useEffect(()=>{
    if (status==='error'){
      setLabel('alert alert-danger');
    }else if(status==='success'){
      setLabel('alert alert-success');
    }else{
      setLabel('');
    }
  },[status])
  return(
    <div className={label} role="alert">
      {msg}
    </div>
  )
}

export default StatusMsg