import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Redirect } from "react-router-dom";
import {insertData} from '../tools/Helper';

import { useAuth } from "../../context/auth";
import StatusMsg from '../tools/StatusMsg';

function Login(props) {

	const referer = props.location.state.referer || '/';

	const [isLoggedIn, setLoggedIn] = useState(false);
	const [isError, setIsError] = useState(false);
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const { setAuthTokens } = useAuth();

	const postLogin = async() =>{
	setIsError(false);

	const result = await insertData("login", {usuario:userName, password:password});
		if (result.status==="success"){
		    setAuthTokens(result);
		    setLoggedIn(true);
		}else{
		    setIsError(true);
		}
	console.log(result);
	}

	if (isLoggedIn) {
		return <Redirect to={referer} />;
		//window.location.reload(true);
	}

  return (
  	<div class="content">
    	<div class="row">
    		<div class="col-md-12">
    			<div id="tabs" className="card">
	        		<div className="container py-4">
	        				<h3>Iniciar Sesion</h3>
	        				{ isError && <StatusMsg status="error" msg="Usuario o contraseña incorrectos" /> }
	        				<Form>
							  <Form.Group controlId="formBasicEmail">
							    <Form.Label>Correo Electronico</Form.Label>
							    <Form.Control type="username"
									value={userName}
									onChange={e => {
									setUserName(e.target.value);
									}}
									placeholder="Correo"
								/>
							  </Form.Group>

							  <Form.Group controlId="formBasicPassword">
							    <Form.Label>Contraseña</Form.Label>
							    <Form.Control type="password"
							        value={password}
							        onChange={e => {
							        	setPassword(e.target.value);
							        }}
							        placeholder="Contraseña"
							        />
							  </Form.Group>
							  <Button variant="primary" onClick={postLogin}>
							    Iniciar Sesion
							  </Button>
							</Form>
	        		</div>
        		</div>
    		</div>
    	</div>
    </div>
  );
}

export default Login;