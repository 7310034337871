import React, {useState,useEffect} from 'react';
import Cancelaciones from './listas/Cancelaciones';
import Pagadas from './listas/Pagadas';

import StatusMsg from '../tools/StatusMsg';
import LoadingMsg from '../tools/LoadingMsg';

import moment from 'moment';
import {insertData,download} from '../tools/Helper';
import { Link } from 'react-router-dom'

import * as XLSX from 'xlsx';

const ListaFacturasContabilidad = ({lista,getLista,addLista,setLista}) => {

	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(false);
	const [status, setStatus] = useState(false);
	const [tab, setTab] = useState(2);

	const [hojas, setHojas] = useState([]);

	let isSubscribed = true;

	const setCurrentTab = async(id) =>{
		if(!loading){
			setLoading(true);
			setTab(id);
			if(id===35){
				setLista([]);
				await getLista(id + "," + (id+1) + "," + (id+3) + "," + (id+4) + "," + (id+6) + "," + (id+7));
			}else{
				setLista([]);
				await getLista(id + "," + (id+1) + "," + (id+4) + "," + (id+5));
			}
			if(isSubscribed){
				setLoading(false);
			}
		}
	}


	useEffect(() => { 
		// eslint-disable-next-line
		setCurrentTab(35);
		// eslint-disable-next-line
		return () => {isSubscribed=false};
	}, [])

	useEffect(() => { 
		// eslint-disable-next-line
		const modificarFecha = async(facturas) =>{
			setStatus("ok");
			if(!loading){
				setLoading(true);
				
				const data = await insertData('cambiar-fecha-pago', facturas);
				if(isSubscribed){
					setStatus(data.status);
					setMessage(data.message);
					setLoading(false);
				}
			}
		}

		if(hojas.length>0){
			modificarFecha(hojas[0].data);
		}
	}, [hojas])

	const handleInputChange = (event) => {

	    const target = event.target
	    const name = target.name

	    let hojas = []
	    if (name === 'file') {
	      let reader = new FileReader()
	      reader.readAsArrayBuffer(target.files[0])
	      reader.onloadend = (e) => {
	        var data = new Uint8Array(e.target.result);
	        var workbook = XLSX.read(data, {type: 'array', cellDates: 'true'});

	        workbook.SheetNames.forEach(function(sheetName) {
	          // Here is your object
	          var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
	          
	          hojas.push({
	            data: XL_row_object
	          })
	        })

	        for(let value of hojas[0].data){
	        	value["Fecha Pago"] = moment(value["Fecha Pago"]).format('YYYY/MM/DD');
	        };
	        
	        setHojas(hojas);
	        console.log(hojas);
	      }
	    }

	    event.target.value = null;
	}

	const bajar = async(id) => {
		const response = await download();
		console.log(response);
		const url = window.URL.createObjectURL(new Blob([response]));
		const link = document.createElement('a');
		  link.href = url;
		  link.setAttribute('download', 'formato.xlsx');
		  document.body.appendChild(link);
		  link.click();
	}

	return (
		<React.Fragment>
		<div className="card">
			<div className="container-fluid px-4">
				<ul className="nav nav-tabs tabs-navigate">
					<li><a className={ tab===35 ? "active" : ""} onClick={ () =>{setCurrentTab(35)}} href="#tabs">Facturas Pagadas</a></li>
					<li><a className={ tab===27 ? "active" : ""} onClick={ () =>{setCurrentTab(27)}} href="#tabs">Cancelaciones / Devoluciones</a></li>
				</ul>
				<div className="tab-content">
					<div id="surtir" className="tab-pane fade in active show">
						<div className="card-body">

							<LoadingMsg loading={loading} />

							{ tab===35 && loading===false ? <Pagadas data={lista} getLista={setCurrentTab} /> : null }
							{ tab===27 && loading===false ? <Cancelaciones data={lista} getLista={setCurrentTab} /> : null }

						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="card">
			<div className="container-fluid px-4">
				<div className="card-body">
					<h3>Modificar fecha de pago</h3>
					<LoadingMsg loading={loading} />
					<StatusMsg status={status} msg={message} />
					<div className="row">
						<div className="col-12 col-md-6">
							<h4>Descargar formato</h4>
							<p>Descargue el siguiente formato para poder modificar la fecha de pago de las facturas: </p>
							<Link to="/formato_pago.xlsx" target="_blank" download><button className="btn btn-primary">Descargar formato</button></Link>
						</div>
						<div className="col-12 col-md-6">
							<h4>Subir formato</h4>
							<p>Seleccione el formato rellenado con los datos para continuar: </p>
							<input 
					            required 
					            type="file" 
					            name="file" 
					            id="file" 
					            onChange={handleInputChange} 
					            placeholder="Archivo de excel" 
					            className="btn btn-primary"
					        />
						</div>
					</div>
				</div>
			</div>
		</div>
		</React.Fragment>
	);
}

export default ListaFacturasContabilidad;