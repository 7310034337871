import React from 'react';

const Simbolos = ({idStatus}) =>{

    idStatus = Number.parseInt(idStatus, 10);

	if(idStatus===11){
        return (
            <div className="text-center">
                <span className="label label-default m-1">CC</span>
            </div>);

    }else if(idStatus===13){
        return (
            <div className="text-center">
                <span className="label label-credito m-1">CC</span>
            </div>);

    }else if(idStatus===25){
		return (
			<div className="text-center">
                <span className="label label-default m-1">AL</span>
                <span className="label label-default m-1 ">FA</span>
                <span className="label label-default m-1">CO</span>
            </div>);

	}else if(idStatus===26){
		return (
			<div className="text-center">
                <span className="label label-almacen m-1">AL</span>
                <span className="label label-default m-1">FA</span>
                <span className="label label-default m-1">CO</span>
            </div>);

	}else if(idStatus===27){
		return (
			<div className="text-center">
                <span className="label label-almacen m-1">AL</span>
                <span className="label label-facturacion m-1">FA</span>
                <span className="label label-default m-1">CO</span>
            </div>);

	}else if(idStatus===28){
		return (
			<div className="text-center">
                <span className="label label-almacen m-1">AL</span>
                <span className="label label-facturacion m-1">FA</span>
                <span className="label label-credito m-1">CO</span>
            </div>);

	}else if(idStatus===29){
		return (
			<div className="text-center">
                <span className="label label-default m-1">AL</span>
                <span className="label label-default m-1">FA</span>
                <span className="label label-default m-1">CO</span>
            </div>);

	}else if(idStatus===30){
		return (
                <div className="text-center">
                    <span className="label label-almacen m-1">AL</span>
                    <span className="label label-default m-1">FA</span>
                    <span className="label label-default m-1">CO</span>
                </div>
            );

	}else if(idStatus===31){
		return (
			<div className="text-center">
                <span className="label label-almacen m-1">AL</span>
                <span className="label label-facturacion m-1">FA</span>
                <span className="label label-default m-1">CO</span>
            </div>);

	}else if(idStatus===32){
		return (
			<div className="text-center">
                <span className="label label-almacen m-1">AL</span>
                <span className="label label-facturacion m-1">FA</span>
                <span className="label label-credito m-1">CO</span>
            </div>);

	}else if(idStatus===35){
        return (
            <div className="text-center">
                <span className="label label-default m-1">CO</span>
            </div>);

    }else if(idStatus===36){
        return (
            <div className="text-center">
                <span className="label label-credito m-1">CO</span>
            </div>);

    }else if(idStatus===38){
        return (
            <div className="text-center">
                <span className="label label-default m-1">CO</span>
            </div>);

    }else if(idStatus===39){
        return (
            <div className="text-center">
                <span className="label label-credito m-1">CO</span>
            </div>);

    }else if(idStatus===41){
        return (
            <div className="text-center">
                <span className="label label-default m-1">CO</span>
            </div>);

    }else if(idStatus===42){
        return (
            <div className="text-center">
                <span className="label label-credito m-1">CO</span>
            </div>);

    }

    return("");
}

export default Simbolos;