import React from 'react';

function Input(props) {

	var listItems;
	if(props.options){
		if(props.type===1){
			 listItems = (props.options).map((opcion) =>
			<option key={opcion.id_tipo_documento} id={opcion.id_tipo_documento}>{opcion.descripcion}</option>);
		}else if(props.type===2){
			 listItems = (props.options).map((opcion) =>
			<option key={opcion.id_tipo_pago} id={opcion.id_tipo_pago}>{opcion.descripcion}</option>);
		}else if(props.type===3){
			 listItems = (props.options).map((opcion) =>
			<option key={opcion.id_tipo_almacen} id={opcion.id_tipo_almacen}>{opcion.descripcion}</option>);
		}else if(props.type===4){
			 listItems = (props.options).map((opcion) =>
			<option key={opcion.id_banco} id={opcion.id_banco}>{opcion.descripcion}</option>);
		}
		
		return(
		    <div className={"col-md-"+props.colSize}>
			    <div className="form-group">
			        <label>{props.label}</label>
			        <select name={props.name} className="form-control" onChange={props.onChange}>
			        	{listItems}
			        </select>
			    </div>
		    </div>
		);
	} else {
		return(
		<div className={"col-md-"+props.colSize}>
		    <div className="form-group">
		        <label>{props.label}</label>
		        <select name={props.name} className="form-control" disabled onChange={props.onChange}>
		        	<option key={1} id={1}>Cargando...</option>
		        </select>
		    </div>
		</div>
		);
	}
	
}

export default Input;