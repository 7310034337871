import React from 'react';
import Modal from 'react-bootstrap/Modal';
import StatusMsg from '../../tools/StatusMsg';
import LoadingMsg from '../../tools/LoadingMsg';

import Botones from './Botones';
import Sugerencias from './Sugerencias';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InputGroup from '../../formComponents/InputGroup';

const FirmarModal = ({show,handleClose,loading,status,message,fecha,id,handleChange,firmarData,titulo,texto,selectLista,entregaPersonal,setTipoPago,data}) =>{

	const lista=["Entrega a personal DIMETAB","Entrega al cliente"];
  	const lista2=["Efectivo", "Transferencia", "Cheque"];

	const fechaNormal = fecha.getFullYear() + ((fecha.getMonth()+1) < 10 ? '-0' : '-') + (fecha.getMonth()+1) + 
	   (fecha.getDate() < 10 ? '-0' : '-') + fecha.getDate() +
	   (fecha.getHours() < 10 ? '  -  0' : '  -  ') + fecha.getHours() + 
	   (fecha.getMinutes() < 10 ? ':0' : ':') + fecha.getMinutes();

	const opciones=()=>{
		if(entregaPersonal===true){
			return(<Form.Group contoldId="tipoEntrega">
		            <InputGroup label="Tipo de pago" options={lista2} onChange={setTipoPago} name="tipo_entrega" />
		        </Form.Group>);
		}
	}

	const formulario=()=>{

		if(status !== "success" && loading === false){
	    return(
	        <Form>
		        <Form.Group controlId="formFactura">
		            <Form.Label>Factura:</Form.Label>
		            <Form.Control type="text" placeholder={id} name="id_factura" value={id} readOnly />
		        </Form.Group>
		        <Form.Group controlId="formDatos">
		            <Row>
		              <Col>
		                <Form.Label>Nombre:</Form.Label>
		                <Form.Control placeholder="Nombre" name="nombre" onChange={handleChange} />
		              </Col>
		              <Col>
		                <Form.Label>Fecha:</Form.Label>
		                <Form.Control placeholder="Fecha" disabled value={fechaNormal} />
		              </Col>
		            </Row>
		        </Form.Group>
		        <Form.Group contoldId="tipoEntrega">
		            <InputGroup label="Tipo de entrega" options={lista} onChange={selectLista} name="tipo_entrega" />
		        </Form.Group>
		        {opciones()}
		        <Form.Group controlId="formFirma">
		            <Form.Label>Firma:</Form.Label>
		            <Form.Control type="text" name="firma" onChange={handleChange} />
		        </Form.Group>
		       	<Form.Group controlId="exampleForm.ControlTextarea1">
				    <Form.Label>Comentario</Form.Label>
				    <Form.Control as="textarea" rows="3" name="comentario" onChange={handleChange}/>
				</Form.Group>
	        </Form>
	    );
    }
    return("");

	}

	return(
	    <Modal show={show} onHide={handleClose}>
	        <Modal.Header closeButton>
	            <Modal.Title>{titulo}</Modal.Title>
	        </Modal.Header>
	        <Modal.Body>
	        	<LoadingMsg loading={loading}/>
	        	<StatusMsg status={status} msg={message}/>
	        	<Sugerencias id={id} />
	        	{formulario()}
	        </Modal.Body>
	        <Modal.Footer>
	        	<Botones 
	        		handleClose={handleClose}
	        		status={status}
	        		loading={loading}
	        		firmarData={firmarData}
	        	/>
	        </Modal.Footer>
	    </Modal>
	);
}

export default FirmarModal;