import React, {useState,useEffect} from 'react';
import {insertData, getData} from '../../tools/Helper';

import RemisionModal from './RemisionModal';

const Captura = ({getLista,handleClose,show,reload,setReload,factura,facturasArray}) =>{

	const fecha = new Date();
	const [catalogo, setCatalogo] = useState([]);

	let isSubscribed = true;

	const getTipos = async() =>{
		const data = await getData('catalogos');
		if (isSubscribed) {
			if(data.status==="success"){
				setCatalogo(data);
				console.log(data)
				const tipoPago = data.pago[0];
				const bancos = data.banco[0];

				setData({
					id_factura: factura.id_factura,
					fecha_pago: '',
					id_tipo_pago: tipoPago.id_tipo_pago,
					id_banco: bancos.id_banco,
					folio_microsip: '',
					facturas: facturasArray,
				})
			}
		}
	}

	const [data, setData] = useState({
		id_factura: factura.id_factura,
		fecha_pago: '',
		folio_microsip: '',
		facturas: facturasArray,
	});

	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("ok");
	const [message, setMessage] = useState("");
	
	const handleChange = e =>{
		setData({
			...data,
			[e.target.name]: e.target.value
		})
	}

	const handleChangeTipo = e =>{

		const selectedIndex = e.target.options.selectedIndex;
		const id = parseInt(e.target.options[selectedIndex].getAttribute('id'));
		setData({
			...data,
			[e.target.name]: id
		})
	}

	useEffect(() => { 
		setData({
			...data,
			id_factura: factura.id_factura,
			fecha_pago: '',
			folio_microsip: '',
			facturas: facturasArray,
		});
		
		if(reload === true){
			setStatus("ok");
			setReload(false);
		}
	}, [factura])

	useEffect(() => { 
		getTipos();
		// eslint-disable-next-line
	   	return () => {isSubscribed=false};
	}, []);

	const firmarData = async () => {
		setLoading(true);
		setMessage("");
		setStatus("ok");

		if(data.facturas.length>0){
			const firmar = await insertData("captura-multiple",data);
			console.log(data);

			if(firmar.status === "error"){
				setMessage("Faltan campos por rellenar.");
				setStatus("error");
			}else if(firmar.status==="success"){
				setStatus("success");
				setMessage("Se han capturado los datos correctamente.");
				setReload(true);
			}else{
				setMessage("Ha ocurrido un error, por favor intente otra vez.");
				setStatus("error");
			}
		}else{
			console.log(data);
			const firmar = await insertData("captura",data);
			
			if(firmar.status === "error"){
				setMessage("Faltan campos por rellenar.");
				setStatus("error");
			}else if(firmar.status==="success"){
				setStatus("success");
				setMessage("Se ha firmado la factura correctamente.");
				setReload(true);
			}else{
				setMessage("Ha ocurrido un error, por favor intente otra vez.");
				setStatus("error");
			}
		}

		setLoading(false);
	};

	return (
		<React.Fragment>
			<RemisionModal 
				handleChange={handleChange} 
				loading={loading} 
				status={status} 
				message={message}
				show={show}
				handleClose={handleClose}
				fecha={fecha}
				id={factura.id_factura}
				firmarData={firmarData}
				titulo={"Insertar datos de captura"}
				factura={data}
				catalogo={catalogo}
				handleChangeTipo={handleChangeTipo}
			/>
		</React.Fragment>
	);
}

export default Captura;