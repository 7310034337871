import React, {useState} from 'react';
import DataTable from 'react-data-table-component';
import {customStyles, paginacionOpciones, Empty, columnasData} from '../../tools/TableStyles';

import BotonInfo from '../../timeline/BotonInfo';
import ModalFirmas from '../../timeline/ModalFirmas';

import BotonComentarios from '../../timeline/BotonComentarios';
import ModalComentarios from '../../timeline/ModalComentarios';

const RemisionesFacturadas =({data,cambiarStatus})=>{

	/**/
	const [showInfo, setShowInfo] = useState(false);
	const [selectedItemInfo, setSelectedItemInfo] = useState(0);

	const setItemInfo = async (id,status) =>{
		await setSelectedItemInfo(status);
		await setShowInfo(true);
	}

	const handleCloseInfo = (status) => setShowInfo(false);
	/**/

	/* MODAL DE COMENTARIO REFACTURACION */
	const [showInfoComentario, setShowInfoComentario] = useState(false);
	const [comentario, setComentario] = useState("No se han introducido comentarios.")

	const showComentario = async (com) =>{
		if(com !== null){
			setComentario(com);
		}
		await setShowInfoComentario(true);
	}

	const closeComentario = (status) => setShowInfoComentario(false);
	/* --- */
	
	const columnas = [
		{
			name: 'TIPO',
			cell: (row) => <span className="text-success font-weight-bold">{row.estatus}</span>,
			maxWidth:"7.5rem"
		},
		{
			name: '',
			cell: (row) => <div>
			<BotonInfo id={row.id_factura} setItem={setItemInfo} /> 
			{row.id_tipo_documento === 7 ? 
				<BotonComentarios comentario={row.comentario} setComentario={showComentario} /> : ""} 
			</div>,
			ignoreRowClick: true,
			compact:true,
			width:"1.8rem"
		},
	]

	return(
		<React.Fragment>
			<DataTable
				columns= {[...columnasData, ...columnas]}
				title = "Remisiones Facturadas"
				data={data}
				pagination
				paginationComponentOptions = {paginacionOpciones}
				fixedHeader
				customStyles={customStyles}
				noDataComponent=<Empty />
			/>
			<ModalFirmas 
				id={selectedItemInfo} 
				show={showInfo}
				handleClose={handleCloseInfo}
			/>
			<ModalComentarios  
				show={showInfoComentario}
				handleClose={closeComentario}
				comentario={comentario}
			/>
			</React.Fragment>
	)
}

export default RemisionesFacturadas;