import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroupTipoPago from '../../formComponents/InputGroupTipoPago';


const Formulario = ({fecha, id, handleChange, status, loading, factura, catalogo, handleChangeTipo}) =>{

	const fechaNormal = fecha.getFullYear() + ((fecha.getMonth()+1) < 10 ? '-0' : '-') + (fecha.getMonth()+1) + 
   (fecha.getDate() < 10 ? '-0' : '-') + fecha.getDate() +
   (fecha.getHours() < 10 ? '  -  0' : '  -  ') + fecha.getHours() + 
   (fecha.getMinutes() < 10 ? ':0' : ':') + fecha.getMinutes();

    if(status !== "success" && loading === false){
	    return(
	        <Form>
		        <Form.Group controlId="formFactura">
		            <Form.Label>Remision:</Form.Label>
		            <Form.Control type="text" placeholder={id} name="id_factura" value={id} readOnly />
		        </Form.Group>
		        <Form.Group controlId="formDatos">
	                <Form.Label>Fecha de Pago:</Form.Label>
	                <Form.Control placeholder="Fecha" disabled value={fechaNormal} />
		        </Form.Group>
		        <Form.Group>
		        	<InputGroupTipoPago label="Tipo de Pago" options={catalogo.pago} onChange={handleChangeTipo} name="id_tipo_pago" value={factura["id_tipo_pago"]} type={2} />
		        </Form.Group>
		        <Form.Group>
		        	<InputGroupTipoPago label="Bancos" options={catalogo.banco} onChange={handleChangeTipo} name="id_banco" value={factura["id_banco"]} type={4} />
		        </Form.Group>
		        <Form.Group controlId="formFirma">
		            <Form.Label>Folio Microsip:</Form.Label>
		            <Form.Control type="text" name="folio_microsip" onChange={handleChange} />
		        </Form.Group>
	        </Form>
	    );
    }
    return("");
}

export default Formulario;