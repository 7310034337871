import React, {useState,useEffect} from 'react';
import {insertData,getData} from '../tools/Helper';

import { useAuth } from "../../context/auth";
import DateTimePicker from 'react-datetime-picker';

import {Col, Row, Button, Tabs, Tab} from 'react-bootstrap';

import StatusMsg from '../tools/StatusMsg';
import LoadingMsg from '../tools/LoadingMsg';

import moment from 'moment';

import jsPDF from "jspdf";
import "jspdf-autotable";

import InputGroupReporte from '../formComponents/InputGroupReporte';

const ReportePDF = () => {

	const [status, setStatus] = useState("ok");
	const [statusMsg, setStatusMsg] = useState("");
	const [listaFacturas, setListaFacturas] = useState([]);
	const [listaClientes, setListaClientes] = useState([""]);
	const [listaZonas, setListaZonas] = useState([""]);
	const [listaVendedores, setListaVendedores] = useState([""]);
	const [catalogoLoaded, setCatalogoLoaded] = useState(false);

	const exportPDF = () => {

	    const unit = "pt";
	    const size = "A4"; // Use A1, A2, A3 or A4
	    const orientation = "portrait"; // portrait or landscape

	    const doc = new jsPDF(orientation, unit, size);

	    const title = "Reporte de Cobranza";
	    const subtitle = "Peridodo de: ";
	    const headers = [["CONCEPTO", "FOLIO", "FECHA", "FECHA PAGO", "VENCIMIENTO", "TOTAL"]];

	    doc.setFontSize(22);
	    var textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
	    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
	    doc.text(textOffset, 40, title);
	    doc.setFontSize(14);
	    doc.text(subtitle + datos.fecha_inicio + " a " + datos.fecha_fin, 40, 75);

	    let data;
	    let x = 0;
	    let content;

	    for (let cliente of Object.keys(listaFacturas)) {
	  		data = listaFacturas[cliente].map(elt=> [elt.tipo_documento, elt.folio_sat, elt.fecha_factura, elt.fecha_pago, elt.vencimiento, elt.total]);
	  		const clienteTx = "Cliente: ";
	  		var total = 0;
	  		for(var factura of listaFacturas[cliente]){
	  			total = total + factura['total'];
	  		}

	  		if(x < 1){
	  			content = {
			      startY: 112,
			      head: headers,
			      body: data,
			      theme: 'plain',
			    };

			    /* PRIMER CLIENTE */
			    doc.setFontSize(12);
			    doc.text(clienteTx + cliente, 40, 100);
			    doc.setFontSize(9);
			    doc.line(40, 110, doc.internal.pageSize.width-40, 110);
			    doc.autoTable(content);
			    /* */
	  		}else{
	  			content = {
			      startY: doc.lastAutoTable.finalY + 36,
			      head: headers,
			      body: data,
			      theme: 'plain',
			    };

			    let lastY = doc.lastAutoTable.finalY;
			    doc.setFontSize(12);
			    doc.text(clienteTx + cliente, 40, lastY + 25);
			    doc.setFontSize(9);
			    doc.line(40, lastY + 32, doc.internal.pageSize.width-40, lastY + 32);
			    doc.autoTable(content);
	  		}
	  		var totalTx = "Total: " + total;
		    var witdhTotal = doc.getStringUnitWidth(totalTx) * 12 / doc.internal.scaleFactor;
		    doc.line(doc.internal.pageSize.width - 100, doc.lastAutoTable.finalY, doc.internal.pageSize.width-40, doc.lastAutoTable.finalY);
		    doc.text(totalTx, doc.internal.pageSize.width - witdhTotal - 50, doc.lastAutoTable.finalY + 15);
	  		x++;
		}
   
	    /* LOS DEMAS CLIENTES */

	    /* */

    	doc.save("report.pdf")
	}

	const { deleteTokens } = useAuth();

	function logOut() {
		deleteTokens();
	}

	/* SELECCION DE RANGO DE FECHA */
	const [datos, setDatos] = useState({
		fecha_inicio: '',
		fecha_fin: '',
		tipo: '',
		vendedor: '',
		zona: '',
		cliente: 'todos',
		inicio: '',
		fin: '',
	});

	const setFechaInicio = (data) =>{
		setDatos({
			...datos,
			'inicio': data,
		})
	}

	const setFechaFin = (data) =>{
		setDatos({
			...datos,
			'fin': data,
		})
	}
	/* --- */

	let isSubscribed = true;

	const getTipos = async() =>{
		const data = await getData('catalogos');
		if (isSubscribed) {
			if(data.status==="success"){
				console.log(data);
				setDatos({
					fecha_inicio: '',
					fecha_fin: '',
					tipo: '',
					vendedor: '',
					zona: '',
					cliente: 'todos',
					inicio: '',
					fin: '',
				});
				setListaClientes(data.cliente);
				setListaVendedores(data.vendedor);
				setListaZonas(data.zona);
				setCatalogoLoaded(true);
			}
		}
	}

	useEffect(() => { 
		getTipos();
		// eslint-disable-next-line
    	return () => {isSubscribed=false};
  	},[]);

	const [loading, setLoading] = useState(false);

	const handleSelect = key => {
    	setDatos({
			...datos,
			tipo: key
		})
		console.log(key)
	}

	const handleChangeCliente = e =>{
		const selectedIndex = e.target.options.selectedIndex;
		const id = e.target.options[selectedIndex].getAttribute('id');
		setDatos({
			...datos,
			[e.target.name]: id
		})
	}

	const getReporte = async() =>{
		setStatus("ok");
		setStatusMsg("");
		if(datos.inicio === '' || datos.fin === ''){
			setStatusMsg("Por favor introduzca una fecha");
			setStatus("error");
		}else{
			setLoading(true);
			let datosFactura = datos;
			datosFactura['fecha_inicio'] = moment(datosFactura['inicio']).format('YYYY/MM/DD');
			datosFactura['fecha_fin'] = moment(datosFactura['fin']).format('YYYY/MM/DD');
			const reporte = await insertData("reportes-cliente", datosFactura);
			console.log(datosFactura);
			if(isSubscribed===true){
				setStatus(reporte.status);
				if(reporte.status === "success"){
					setListaFacturas(reporte.datos);
					console.log(reporte.datos);
				}
				setStatusMsg(reporte.message);
				console.log(reporte);
				setLoading(false);
			}
		}
	}

	return(
		<div className="card">
			<div className="container-fluid px-4">
				<p className="mt-3">Introduzca un rango de fechas</p>
					<Tabs defaultActiveKey={1} id="uncontrolled-tab-example" className="w-100" onSelect={handleSelect}>
					  <Tab eventKey={1} title="Por cliente" className="pt-2">
					  	<InputGroupReporte catalogoLoaded={catalogoLoaded} type={1} label="Cliente" options={listaClientes} onChange={handleChangeCliente} name="cliente" value={datos["cliente"]} />
					  </Tab>
					  <Tab eventKey={2} title="Por datos cobranza" className="pt-2">
					  	<Row>
							<Col>
								<InputGroupReporte catalogoLoaded={catalogoLoaded} type={2} label="Zona" options={listaZonas} onChange={handleChangeCliente} name="zona" value={datos["zona"]} />
							</Col>
							<Col>
								<InputGroupReporte catalogoLoaded={catalogoLoaded} type={3} label="Vendedor" options={listaVendedores} onChange={handleChangeCliente} name="vendedor" value={datos["vendedor"]} />
							</Col>
						</Row>
					  </Tab>
					</Tabs>
					<Row>
					<Col>
						<div className="form-group">
							<label>Fecha</label>
							<DateTimePicker
								onChange={setFechaInicio}
								value={datos.inicio}
								className="form-control c-padding"
								disableClock={true}
								name="inicio"
							/>
						</div>
					</Col>
					<Col>
						<div className="form-group">
							<label>Fecha</label>
							<DateTimePicker
								onChange={setFechaFin}
								value={datos.fin}
								className="form-control c-padding"
								disableClock={true}
								name="fin"
							/>
						</div>
					</Col>
					<Col>
						<Button className="mt-4" variant="primary" onClick={getReporte}>
							Aceptar
						</Button>
					</Col>
				</Row>
				<hr className="mb-1"/>
				<div className="tab-content">
					<div id="surtir" className="tab-pane fade in active show">
						<div className="card-body">

							<LoadingMsg loading={loading}/>
							<StatusMsg status={status} msg={statusMsg}/>

							{status==="success" ? <button className="btn btn-success btn-round" onClick={() => exportPDF()}>Descargar Reporte</button> : "" }

						</div>
					</div>
				</div>
			</div>
		</div>
	);

}

export default ReportePDF;